// base
// export const BaseUrl = 'https://api.1cminc.ca'
// export const BaseUrl = 'http://localhost:5008'
// export const BaseUrl = 'https://menuboard-api.ammag.tech'
export const BaseUrl = 'https://production-be.1cminc.ca'
// export const BaseUrl = 'https://be-test.1cminc.ca'
export const ApiUrl = BaseUrl

//ad_log
export const GetStoreAdLog = BaseUrl + '/log/reports/store/';
export const GetScreenAdLog = BaseUrl + '/log/reports/screen/';

//ad_push
export const postAdPushURL = BaseUrl + '/ad_push';

// admin
export const LogInUrl = BaseUrl + '/admin/login'
export const LogInProduct = BaseUrl + '/admin/product_login'
export const GetAdminProfileUrl = BaseUrl + '/admin/profile'
export const GetAdminApi = BaseUrl + '/admin/get'
export const GetAllAdminApi = BaseUrl + '/admin/getadmin'
export const CreateAdmin = BaseUrl + '/admin/create'
export const AdminEdit = BaseUrl + '/admin/edit'
export const ChangeRole = BaseUrl + '/admin/change_role'
export const UserDeleteEnd = BaseUrl + '/admin/delete'
export const ChangePasswordEnd = BaseUrl + '/admin/change_password'
export const ChangeOthersPassEnd = BaseUrl + '/admin/reset_others_password'
export const ForgotPassGetOTPEnd = BaseUrl + '/admin/forgot-pass-get-otp'
export const ForgotPassGetEmailEnd = BaseUrl + '/admin/forgot_pass_get_email'
export const ResetForgotPassGetOTPEnd = BaseUrl + '/admin/resend_forgot_pass_get_otp'
export const ForgotPassSetPassEnd = BaseUrl + '/admin/set-pass-forget-pass'



// store
export const GetAllStoreAPI = BaseUrl + '/store/get'
export const StoreAPI = BaseUrl + '/store/get'
export const StoreAllAPI = BaseUrl + '/store/getAll'
export const StoreCreate = BaseUrl + '/store/create'
export const StoreEdit = BaseUrl + '/store/edit'
export const pushAll = BaseUrl + '/store/pushAll'

export const DeleteStoreEnd = BaseUrl + '/store/delete'
export const StoreLogInUrl = BaseUrl + '/store/login'
export const GetAllTagUrl = BaseUrl + '/store/get_all_tags'
export const GetInfoForOwner = BaseUrl + '/store/get_for_store_owner'
export const GetAllTypesUrl = BaseUrl + '/store/get_all_types'
export const GetAllStoresList = BaseUrl + '/store/list'


// folder
export const GetAllFoldersEnd = BaseUrl + '/folder/get'
export const FolderCreateEnd = BaseUrl + '/folder/create'
export const FolderEditEnd = BaseUrl + '/folder/edit'
export const FolderDeleteEnd = BaseUrl + '/folder/delete'
// company
export const GetAllCompanyEnd = BaseUrl + '/company/get'
export const CompanyCreateEnd = BaseUrl + '/company/create'
export const CompanyEditEnd = BaseUrl + '/company/edit'
export const CompanyDeleteEnd = BaseUrl + '/company/delete'



// ad
export const AdGetEnd = BaseUrl + '/ad/get'
export const AdGetEndFolder = BaseUrl + '/ad/getwithfolder'
export const AdGetEndAll = BaseUrl + '/ad/getwithAllAds'
export const AdEditEnd = BaseUrl + '/ad/edit'
export const AdCreateEnd = BaseUrl + '/ad/create'
export const DeleteAdEnd = BaseUrl + '/ad/delete'

// file
export const FileUploadEnd = BaseUrl + '/file/upload'

// theme
export const GetThemeEnd = BaseUrl + '/theme/get'
export const CreateThemeEnd = BaseUrl + '/theme/create'
export const EditThemeEnd = BaseUrl + '/theme/edit'
export const DeleteThemeEnd = BaseUrl + '/theme/delete'


// screens

export const GetScreenEnd = BaseUrl + '/screen/get'
export const CreateScreenEnd = BaseUrl + '/screen/create'
export const EditScreenEnd = BaseUrl + '/screen/edit'
export const DeleteScreenEnd = BaseUrl + '/screen/delete'
export const GetAllCateEnd = BaseUrl + '/screen/get_all_categories'
export const GetScreen = BaseUrl + '/screen'
export const GetAllScreenAd = BaseUrl + '/screen/get_products_Ad'

//reports for
export const GetReportsEnd = BaseUrl + '/report/get'

//Ticketing Panel named as Task

export const GetAllTasksAPI = BaseUrl + '/tasks'
export const TaskAPI = BaseUrl + '/tasks'
export const TaskCreate = BaseUrl + '/tasks'
export const TaskEdit = BaseUrl + '/tasks'
export const DeleteTaskEnd = BaseUrl + '/tasks/{task_id}'








