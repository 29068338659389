import axios from 'axios';
import { CompanyEditEnd, FileUploadEnd, TaskCreate } from './api.constants';
import Toast from '../utils/Toast/Toast';

const handleImageUpload = async (file, companyData, setCompanyData, setPhotoSpinner) => {
    const allowedExtensions = ["mp4", "webp", "mkv", "gif"];



    const formData = new FormData();
    formData.append('files', file);

    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    formData.append('files', file);
    if (allowedExtensions.includes(fileExtension)) {
      Toast('err', 'Invalid image format');
      return
    }
    setPhotoSpinner(true);
    try {
        const res = await axios.post(FileUploadEnd, formData, {
            headers: {
                menuboard: localStorage.getItem('menu_token'),
            },
        });
        if (res.status === 200) {
            setCompanyData({ ...companyData, company_logo: res?.data?.files[0].path });
            setPhotoSpinner(false);
            Toast('success', 'Photo uploaded successfully');
        }
    } catch (error) {
        setPhotoSpinner(false);
    }
};
const postCompanyData = async (companyData,data) => {
    try {
        const res = await axios
            .put(
                CompanyEditEnd,
                { ...companyData, id: data?._id },
                {
                    headers: {
                        menuboard: localStorage.getItem('menu_token'),
                    },
                }
            )
        return res;
    } catch (error) {
        return
    }
};

const TicketData = async (tickets,userData) => {
    try {
        const res = await axios
            .post(
                TaskCreate,
                { ...tickets, company_id: userData?.company_id },
                {
                    headers: {
                        menuboard: localStorage.getItem('menu_token'),
                    },
                }
            )
        return res;
    } catch (error) {
        return
    }
};
export { handleImageUpload, postCompanyData ,TicketData};
