import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { ChangePasswordEnd } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import HideShowToggle from '../../../components/HideShowToggle/HideShowToggle'
import "./ResetPassword.scss"
import ReactPasswordChecklist from 'react-password-checklist'
import { lowerCaseRegex, minLengthRegex, upperCaseRegex, numberRegex, specialCharRegex, maxLengthRegex } from '../../../constants/regex'
import { Maxlength } from '../../../constants/const'
const ResetPassword = ({ data, show, handleClose }) => {
  const [spinner, setSpinner] = useState(false)
  const [pass, setPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [passVisibility, setPassVisibility] = useState(false)
  const [passVisibility1, setPassVisibility1] = useState(false)

  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasMinLength: false,
    hasMaxLength: false,
  });

  const validatePassword = (newPass) => {
    const hasLowerCase = lowerCaseRegex.test(newPass);
    const hasUpperCase = upperCaseRegex.test(newPass);
    const hasNumber = numberRegex.test(newPass);
    const hasMinLength = minLengthRegex.test(newPass);
    const hasSpecialChar = specialCharRegex.test(newPass); // Check for special character
    const hasMaxChar = maxLengthRegex.test(newPass); // Check for special character
    setValidations({ hasLowerCase, hasUpperCase, hasNumber, hasMinLength, hasSpecialChar, hasMaxChar });
  };
  const isMaxLengthValid = newPass.length >=1;
  const handleSubmit = async () => {
    setSpinner(true)

    if (pass === '') {
      Toast('err', `Password can't be empty`)
      setSpinner(false)
      return
    }
    if (newPass === '') {
      Toast('err', `New password can't be empty`)
      setSpinner(false)
      return
    }

    
    if (!validations?.hasLowerCase || !validations?.hasMinLength || !validations?.hasNumber || !validations?.hasUpperCase || !validations?.hasSpecialChar) { // Update conditions
      setSpinner(false)
      return
    }
    if (newPass.length > 32) {
      setSpinner(false)
      return
    }


    try {
      const response = await axios.put(
        ChangePasswordEnd,
        {
          id: data?._id,
          password: pass,
          new_password: newPass,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'Password updated!')
        handleClose()
        setSpinner(false)
        setPass('')
        setNewPass('')
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      // setPass('')
      // setNewPass('')
      // handleClose()
      setSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }


  return (
    <Modal show={show} onHide={handleClose} size='md'>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title style={{ fontSize: '22px' }}>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <div className='plain-input my-3'>
            <label for=''>Current Password</label>
            <br />
            <input

              type={passVisibility ? 'text' : 'password'}
              value={pass}
              placeholder='Input current password'
              onChange={(e) => setPass(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              autoComplete='new-password' // Set autocomplete to "new-password"

            />
            <HideShowToggle
              passVisibility={passVisibility}
              setPassVisibility={() => setPassVisibility(!passVisibility)}
            />
          </div>

          <div className='plain-input my-3'>
            <label for=''>New Password</label>
            <br />
            <input
              type={passVisibility1 ? 'text' : 'password'}
              placeholder='Minimum length 10 characters'
              value={newPass}
              onChange={(e) => {
                setNewPass(e.target.value);
                validatePassword(e.target.value); // Change to newPass
              }}
              autoComplete='new-password' // Set autocomplete to "new-password"

              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />

            <HideShowToggle
              passVisibility={passVisibility1}
              setPassVisibility={() => setPassVisibility1(!passVisibility1)}
            />

          </div>
          <ReactPasswordChecklist
            style={{ marginTop: "10px" }}
            rules={["minLength", 'maxLength', "specialChar", "number", "capital", "lowercase"]}
            minLength={10}
            maxLength={isMaxLengthValid ? 32 : -1} // Set
            value={newPass}
            // valueAgain={passwordAgain}
            messages={{
              minLength: "Password length minimum 10 characters",
              maxLength: "Password length maximun 32 characters",
              specialChar: "Password has special character.",
              number: "Password has number.",
              capital: "Password has a capital letter",
              lowercase: "Password has a lowercase letter",

            }}
          />


        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='primary-btn-light' onClick={handleClose}>
          Close
        </button>
        <button
          className='primary-btn d-flex justify-content-center align-items-center'
          onClick={() => handleSubmit()}
          type='submit'
        >
          Update Password{' '}
          <Spinner
            animation='border'
            size='sm'
            className={spinner ? 'd-block ms-2' : 'd-none ms-2'}
          />
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResetPassword

