import { useEffect, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import threedot from '../../assets/icons/threedot.svg'
import Frame from '../../assets/images/Frame.png'
import ScrnImg from '../../assets/images/screen-img.png'
import { DeleteScreenModal } from '../Modals/DeleteScreenModal'
import { EditScreenModal } from '../Modals/EditScreenModal'
import './Screens.scss'
import axios from 'axios'
import { AdGetEnd } from '../../constants/api.constants'
import Toast from '../../utils/Toast/Toast'

const Screens = ({ screen, loadStoreScreen, editEnable, AllscreenAd }) => {
  const [show, setShow] = useState(false)
  const [ad, setAd] = useState([])
  const [screeenAd, setscreenAd] = useState([])
  const history = useHistory()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseForDeleteModal = () => setShowDeleteModal(false)

  const [editScreen, setEditScreen] = useState(false)

  const HTTP = {
    OK: 200
  }
  const goToScreen = () => {
    setEditScreen(true)
  }
  useEffect(() => {
    const ScreenArr = [screen]
    setscreenAd(ScreenArr)
  }, [])
  const goToEmojiGifPage = (id) => {
    history.push(`/product-list/${id}`)
  }
  useEffect(() => {
    // loadAllAds()
  }, [])
  const loadAllAds = async () => {
    let end = AdGetEnd + `?all_ads=true`

    try {
      const res = await axios.get(end, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (res.status === HTTP.OK) {
        setAd(res?.data?.data)


      } else throw new Error(res?.data?.msg)
    } catch (error) {
    }
  }


  return (
    <div className='d-flex py-4'>
      <div className='screen-bg' >
        <div
          className='preview-bg '
          style={{
            backgroundImage: `url('${screen?.preview || ScrnImg}')`,
          }}
        >
          {screen?.preview ? (
            <img
              src={Frame}
              alt=''
              style={{
                // position: 'relative',
                // right: '10rem',
                // top: '.2rem',
                cursor: 'pointer',

              }}
              onClick={() => handleShow(true)}
            />
          ) : (
            <h6 className='fw-bold text-white'>No Preview</h6>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <h6 className='fw-bold'>{screen?.screen_name}</h6>
          <h6>
            Categories:{' '}
            {screen?.category?.map((c, idx) =>
              idx !== 0
                ? ', ' +
                c?.name +
                `(${c?.product_count_end - c?.product_count_start})`
                : c?.name + `(${c?.product_count_end - c?.product_count_start})`
            )}
          </h6>
          <h6>Theme: {screen?.theme_id?.name}</h6>
          <h6>
            Ad name: {
              AllscreenAd ? AllscreenAd : "No Any Add"
            }
          </h6>





          <h6> Unique Id: {screen?.unique_id}</h6>
        </div>
      </div>
      {editEnable && (
        <div className='drop-fields' >
          <Dropdown drop='start' style={{ cursor: 'pointer' }}>
            <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
              <img src={threedot} alt='' className='' />
            </Dropdown.Toggle>

            <Dropdown.Menu className='mt-4'>
              <Dropdown.Item className='fw-bold ' onClick={() => goToScreen()}>
                Edit Screen
              </Dropdown.Item>
              <Dropdown.Item className='fw-bold ' onClick={() => goToEmojiGifPage(screen?.unique_id)}>
                Add Emoji/Sticker
              </Dropdown.Item>
              <Dropdown.Item
                className='fw-bold text-danger'
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <Modal show={show} onHide={handleClose} size='xl' centered>
        <img src={screen?.preview} alt='' />
      </Modal>
      {
        localStorage.getItem("user_role") === "ADMIN" && (
          <>
            <EditScreenModal
              show={editScreen}
              handleClose={() => setEditScreen()}
              data={screen}
              loadStoreScreen={loadStoreScreen}
            />
            <DeleteScreenModal
              show={showDeleteModal}
              handleClose={handleCloseForDeleteModal}
              loadStoreScreen={loadStoreScreen}
              data={screen}
            />
          </>
        )
      }


    </div>
  )
}

export default Screens
