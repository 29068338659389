import { toast } from 'react-toastify';

const Toast = (type, msg, currentStatus) => {
  // Check if there's an existing toast
  const existingToastId = toast.isActive('custom-toast');
  
  // Close any existing toast
  if (existingToastId) {
    toast.update(existingToastId, {
      render: msg,
      type: type === 'err' ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      autoClose: 3000,
    });
  } else {
    // Show the new toast
    type === 'err'
      ? toast.error(msg, { toastId: 'custom-toast' })
      : toast.success(msg, { toastId: 'custom-toast' });
  }
};

export default Toast;
