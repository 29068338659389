import React from 'react'
import SideBar from '../CommonLayout/SideBar'
import StoreSideBar from '../CommonLayout/StoreSideBar'
import CompanySideBar from '../CommonLayout/companySidebar'
const DashBoard = ({ children }) => {
  return (
    <div className=''>
      {/* {localStorage.getItem('menu_token') ? (<SideBar />) : (<StoreSideBar />)} */}
      <div>{children}</div>
    </div>
  )
}

export default DashBoard
