import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import DashBoard from '../../components/DashBoard/DashBoard'
import { GetAdminProfileUrl, GetReportsEnd } from '../../constants/api.constants'
import { headingColor, textColor, SPINNER_COLOR } from '../../constants/const'
import Profile from '../Profile/Profile'
import ActiveResetPassword from '../../components/Modals/ActiveResetPassword/ActiveResetPassword'
import ResetPassword from '../../components/Modals/ResetPassword/ResetPassword'
import "./Home.scss"
const Home = () => {
  const currentDate = new Date().toISOString().split('T')[0]

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [reports, setReports] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMsgstart, setErrorMsgStart] = useState('');
  const [errorMsgENd, setErrorMsgEnd] = useState('');
  const [deleteModal, setDeleteModal] = useState(false)
  const [check, setcheck] = useState(false)
  const today = new Date().toISOString().slice(0, 10);
  const [resetPassword, setResetPassword] = useState(false)
  const [userData, setUserData] = useState({})
  useEffect(() => {
    getAdminInfo()
  }, [])

  const getAdminInfo = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
        localStorage.setItem("isActive", response.data.data.isActive)
        if (response.data.data.isActive == true ) {
          setDeleteModal(true)

        }
      } else throw new Error(response?.data?.msg)
    } catch (error) {
    }
  }
  useEffect(() => {
    getReport()
  }, [startDate, endDate])

  const getReport = async () => {
    if (new Date(endDate) < new Date(startDate)) {
      setErrorMsg('End date must be greater than or equal to start date');
    } else {
      // Your logic to handle the submission
      setErrorMsg('');
      setShowSpinner(true)
      try {
        const res = await axios.get(
          GetReportsEnd + `?from=${startDate}&till=${endDate}`,
          {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          }
        )

        if (res.status === 200) {
          setReports(res?.data?.data)
          setShowSpinner(false)
        } else throw new Error(res?.data?.msg || 'Try again later!')
      } catch (error) {
        setShowSpinner(false)
      }
    }
  }
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    const today = new Date();

    // Ensure start date is not greater than today's date
    if (new Date(selectedStartDate) > today) {
      setErrorMsgStart('Start date cannot be greater than today.');
      return;
    }

    // Ensure end date is greater than start date

    setErrorMsgStart('');

  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setEndDate(selectedEndDate);

    // Assuming 'today' is defined somewhere in your code
    const today = new Date();

    if (new Date(selectedEndDate) >= today) {
      setErrorMsgEnd('End date cannot be greater than today.');
    } else {
      setErrorMsgEnd('');
    }

    // Assuming 'startDate' is already defined or set somewhere in your code
    if (new Date(selectedEndDate) < new Date(startDate)) {
      setErrorMsg('End date must be greater than or equal to start date');
    } else {
      setErrorMsg(''); // Clear the error message if it's no longer applicable
    }
  };


  return (
    <>
      <div className='row py-3'>
        {/* <div className='col-3'>
        <DashBoard />
      </div> */}
        <div className='col-12'>
          <h3>Dashboard</h3>

          <div className='mb-3 d-flex justify-content-between align-item-center '>
            <div className='plain-input mt-3 me-2 '>
              <label htmlFor='startDate'>Start Date</label>
              <br />
              <input
                type='date'
                id='startDate'
                style={{ textTransform: 'uppercase' }}
                onChange={handleStartDateChange}
                value={startDate}
                max={today}
              />
              {errorMsgstart && <p style={{ color: 'red' }}>{errorMsgstart}</p>}
            </div>
            <div className='plain-input mt-3 '>
              <label htmlFor='endDate'>End Date</label>
              <br />
              <input
                type='date'
                id='endDate'
                style={{ textTransform: 'uppercase' }}
                onChange={handleEndDateChange}
                value={endDate}
                min={startDate}
                max={today}
              />
              {(errorMsg || errorMsgENd) && <p style={{ color: 'red' }}>{errorMsg || errorMsgENd}</p>}
            </div>

          </div>
          {showSpinner && (
            <div className='d-flex justify-content-center align-items-center my-5'>
              <Spinner animation='border' style={{ color: SPINNER_COLOR }} />
            </div>
          )}
          <div className='d-flex'>
            {
              !showSpinner && (
                <>
                  <div
                  className='overview_side'
                   
                  >
                    <h4
                      className='overview_title px-4 pt-4 pb-3 d-flex justify-content-start align-items-center'
                    >
                      Overview{' '}
                      {showSpinner && (
                        <Spinner className='ms-2' animation='border' size='sm' />
                      )}
                    </h4>
                    {
                      userData?.effective_role==='owner' && (
                        <div className='d-flex justify-content-between align-start px-4 pt-2 row'>
                        <div className='col-9'>
                          <h5 style={{ color: headingColor }}>
                            Total Number of Companyfronts{' '}
                          </h5>
                          <p style={{ color: textColor }}></p>
                        </div>
                        <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                          {reports?.company || 0}
                        </h1>
                      </div>
                      )
                    }
                   
                    <div className='d-flex justify-content-between align-start px-4 pt-2 row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>
                          Total Number of Storefronts{' '}
                        </h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.store || 0}
                      </h1>
                    </div>

                    <div className='d-flex justify-content-between align-start px-4  row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>Total Number of Screens </h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.screen || 0}
                      </h1>
                    </div>

                    <div className='d-flex justify-content-between align-start px-4  row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>Total Number of Adverts</h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.ad || 0}
                      </h1>
                    </div>

                    <div className='d-flex justify-content-between align-start px-4 row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>
                          Total Number of System Admins{' '}
                        </h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.admin || 0}
                      </h1>
                    </div>
                    <div className='d-flex justify-content-between align-start px-4 row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>
                          Total Number of System Managers
                        </h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.manager || 0}
                      </h1>
                    </div>
                    <div className='d-flex justify-content-between align-start px-4 row'>
                      <div className='col-9'>
                        <h5 style={{ color: headingColor }}>Total Number of Themes</h5>
                        <p style={{ color: textColor }}></p>
                      </div>
                      <h1 className='col-3 text-end pe-4' style={{ color: '#333333' }}>
                        {reports?.theme || 0}
                      </h1>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div >
      <ActiveResetPassword
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        ResetPassword={() => setResetPassword(true)}
      />
       <ResetPassword
        data={userData}
        show={resetPassword}
        handleClose={() => setResetPassword()}
      />
      
    </>
  )
}

export default Home
