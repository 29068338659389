import { useState, useEffect } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import threedot from '../../assets/icons/threedot.svg'
import Frame from '../../assets/images/Frame.png'
import ScrnImg from '../../assets/images/screen-img.png'
import { DeleteScreenModal } from '../Modals/DeleteScreenModal'
import { EditScreenModal } from '../Modals/EditScreenModal'
import { AddProductTagModal } from '../Modals/AddProductTagModal'
import { Emoji } from 'emoji-picker-react'
import sampleProduct from '../../assets/images/sample_product.jpg'
import sampleNoImage from '../../assets/images/sample_no_image.jpg'
// import { AddGIFTagModal } from '../Modals/AddGIFTagModal'
import { GetScreen } from '../../constants/api.constants'
import './ProductComponent.scss'
import Toast from '../../utils/Toast/Toast'
import axios from 'axios'

const ProductComponent = ({ screenId, product, loadProductData, editEnable }) => {
  //   const [show, setShow] = useState(false)
  const history = useHistory()
  const [showEditProductTagModal, setShowEditProductTagModal] = useState(false);
  const [showAddGIFModal, setShowAddGIFModal] = useState(false);
  const [showAddTextModal, setShowAddTextModal] = useState(false);
  const [productTag, setProductTag] = useState({})
  const emoji = '&#x1F606;';
  const handleProductTag = (tag, type) => setProductTag({
    tag_value: tag,
    tag_type: type
  })
  const handleCloseTagModal = () => setShowEditProductTagModal(false);
  const handleCloseGIF = () => setShowAddGIFModal(false);
  const handleCloseText = () => setShowAddTextModal(false);

  
  const showEmoji = () => {
    setShowEditProductTagModal(true)
  }
  const showGIF = () => {
    setShowAddGIFModal(true);
  }
  const showTextModal = () => {
    setShowAddTextModal(true)
  }
  useEffect(() => {
    setProductTag({
      tag_value: product.tag_value,
      tag_type: product.tag_type
    })
  }, [])
  const handleDeleteGIFTag = async (e) => {
    try {

      const config = {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      };

      const response = await axios.delete(
        GetScreen + `/${screenId}` + `/${product.id}`,
        config
      );

      if (response.status === 200) {
        Toast('success', 'Tag successfully deleted');
        setProductTag({
          tag_value: '',
          tag_type: ''
        })
      }

    } catch (error) {
      Toast('err', error.response?.data?.msg);
    }
  };


  return (
    <div style={{ display: 'flex', gap: '1rem', backgroundColor: '#dfdfdf', paddingBlock: '2%', marginBlock: '1.5%', borderRadius: '20px', boxShadow: '2.5px 5px #bfbfbf' }}>
      <div style={{ display: 'flex', gap: '2rem', width: '90%', marginLeft: '3%' }}>
        <div
          style={{ width: '10%' }}
        >
          <img
            src={product.imageUrl ? (product.imageUrl) : (sampleNoImage)}
            width={'100px'}
            height={'100px'}
            alt={'No Image'}
          >
          </img>
        </div>

        <div
          style={{ display: 'flex', width: "90%", gap: '10rem', marginLeft: '3%' }}>
          <div style={{ marginBlock: 'auto', width: '50%' }}>
            <h6
              className='fw-bold'
            >
              {product?.name}</h6>
          </div>
          <div
            style={{ marginBlock: 'auto', width: '50%' }}
          >
            {productTag.tag_value && (
              <>

                {productTag.tag_type === 'gif' ? (
                  <img
                    src={productTag.tag_value}
                    height='70px'
                  // width='100px'
                  >
                  </img>
                ) : (
                  <h6>Tag: {productTag.tag_value}</h6>
                )}
              </>
            )}

          </div>

        </div>
      </div>
      {editEnable && (
        <div style={{ marginBlock: 'auto', marginRight: '2%' }}>
          <Dropdown drop='start' style={{ cursor: 'pointer' }}>
            <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
              <img src={threedot} alt='' className='' />
            </Dropdown.Toggle>

            <Dropdown.Menu className='mt-4'>
              <Dropdown.Item className='fw-bold ' onClick={() => showEmoji()}>
                Add Tag
              </Dropdown.Item>
              {

              }
              {productTag.tag_value && (
                <Dropdown.Item className='fw-bold' onClick={() => handleDeleteGIFTag()}>
                  Remove Tag
                </Dropdown.Item>
              )}

            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <AddProductTagModal
        screenId={screenId}
        productId={product.id}
        show={showEditProductTagModal}
        handleClose={handleCloseTagModal}
        handleProductTag={handleProductTag}
      />
      {/* <AddGIFTagModal
            show={showAddGIFModal}
            handleClose={handleCloseGIF}
        /> */}

    </div>
    // <div className='d-flex py-4'>
    //   <div style={{display: 'flex',gap:'1rem'}}>
    //     <div
    //       className='preview-bg '
    //       style={{
    //         backgroundImage: `url('${screen?.preview || ScrnImg}')`,
    //         width:"15rem",
    //         height:"10rem"
    //       }}
    //     >
    //       {screen?.preview ? (
    //         <img
    //           src={Frame}
    //           alt=''
    //           style={{
    //             // position: 'relative',
    //             // right: '10rem',
    //             // top: '.2rem',
    //             cursor: 'pointer',

    //           }}
    //           onClick={() => handleShow(true)}
    //         />
    //       ) : (
    //         <h6 className='fw-bold text-white'>No Preview</h6>
    //       )}
    //     </div>
    //     <div style={{ flex:1 }}>
    //       <h6 className='fw-bold'>{screen?.screen_name}</h6>
    //       <h6>
    //         Categories:{' '}
    //         {screen?.category?.map((c, idx) =>
    //           idx !== 0
    //             ? ', ' +
    //               c?.name +
    //               `(${c?.product_count_end - c?.product_count_start})`
    //             : c?.name + `(${c?.product_count_end - c?.product_count_start})`
    //         )}
    //       </h6>
    //       <h6>Theme: {screen?.theme_id?.name}</h6>
    //       <h6> Unique Id: {screen?.unique_id}</h6>
    //     </div>
    //   </div>
    //   {editEnable && (
    //     <div>
    //       <Dropdown drop='start' style={{ cursor: 'pointer' }}>
    //         <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
    //           <img src={threedot} alt='' className='' />
    //         </Dropdown.Toggle>

    //         <Dropdown.Menu className='mt-4'>
    //           <Dropdown.Item className='fw-bold ' onClick={() => goToScreen()}>
    //             Edit Screen
    //           </Dropdown.Item>
    //           <Dropdown.Item className='fw-bold ' onClick={() => goToEmojiGifPage(screen?.unique_id)}>
    //             Add Emoji/Sticker
    //           </Dropdown.Item>
    //           <Dropdown.Item
    //             className='fw-bold text-danger'
    //             onClick={() => setShowDeleteModal(true)}
    //           >
    //             Delete
    //           </Dropdown.Item>
    //         </Dropdown.Menu>
    //       </Dropdown>
    //     </div>
    //   )}
    //   <Modal show={show} onHide={handleClose} size='xl' centered>
    //     <img src={screen?.preview} alt=''/>
    //   </Modal>
    //   <EditScreenModal
    //     show={editScreen}
    //     handleClose={() => setEditScreen()}
    //     data={screen}
    //     loadStoreScreen={loadStoreScreen}
    //   />
    //   <DeleteScreenModal
    //     show={showDeleteModal}
    //     handleClose={handleCloseForDeleteModal}
    //     loadStoreScreen={loadStoreScreen}
    //     data={screen}
    //   />
    // </div>
  )
}

export default ProductComponent
