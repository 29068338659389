import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import {
  ChangePasswordEnd,
  ForgotPassGetEmailEnd,
  ForgotPassGetOTPEnd,
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'

const ForgotPassGetOTPModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [spinner, setSpinner] = useState(false);
  let history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    if (email === '') {
      Toast('err', 'email cant be empty');
      setSpinner(false);
      return;
    }

    try {
      const response = await axios.put(
        ForgotPassGetEmailEnd ,
        {
          email: email,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        handleClose()
        setSpinner(false)
        setEmail('')
        history.push({
          pathname: '/forgot-pass-reset',
          state: { email: email },
          path:"/login"
        });
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )

    } catch (error) {
      setEmail('');
      handleClose();
      setSpinner(false);
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      );
    }
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="plain-input my-3">
              <label htmlFor="">Enter Your Email</label>
              <br />
              <input
                type="email"
                value={email}
                placeholder="Abc@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleEnterKeyPress}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="primary-btn-light" onClick={handleClose}>
            Close
          </button>
          <button
            type="submit"
            className="primary-btn d-flex justify-content-center align-items-center"
          >
            Update Password{' '}
            <Spinner
              animation="border"
              size="sm"
              className={spinner ? 'd-block ms-2' : 'd-none ms-2'}
            />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};


export default ForgotPassGetOTPModal
