import React from 'react';
import { Modal } from 'react-bootstrap';

const AdminDetails = ({ show, handleClose, data, store }) => {
  const foundStore = store.find((store) => store._id === data?.store);



  return (
    <Modal show={show} onHide={handleClose} style={{ maxWidth: "auto" }}>
      <Modal.Header style={{ border: 'none' }}>
        <Modal.Title>
          {data?.effective_role}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-secondary'>
        <div className='d-flex justify-content-between align-items-center '>
          <h4 className='fw-bold'>Name: </h4>
          <h4>{data?.name}</h4>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='fw-bold'>Email: </h4>
          <h4>{data?.email}</h4>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='fw-bold'>Phone: </h4>
          <h4>{data?.phone}</h4>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='fw-bold'>Role: </h4>
          <h4>{data?.effective_role}</h4>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='fw-bold'>Store Name: </h4>
          <h4>{foundStore?.name ?? "No Store"}</h4>
        </div>

        <div className='col-12 d-flex justify-content-between align-items-start'>
          <h4 className='col-8 fw-bold'>All Roles: </h4>
          <h4 className='col-4'>
            {data && data.role && data.role.slice().reverse().map((dt, idx) => (
              <span key={idx}>{dt}, {'  '}</span>
            ))}
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className='primary-btn ' onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminDetails;
