import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Pagination, Spinner } from 'react-bootstrap'
import plus from '../../assets/icons/plus.svg'
import DashBoard from '../../components/DashBoard/DashBoard'
import { CreateThemeModal } from '../../components/Modals/CreateThemeModal'
import ThemeCard from '../../components/ThemeCard/ThemeCard'
import { GetAdminProfileUrl, GetThemeEnd } from '../../constants/api.constants'
import Toast from '../../utils/Toast/Toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const ThemeManagement = () => {
  const [allThemes, setAllThemes] = useState([])
  const [page, setPage] = useState(1)
  const [documentCount, setDocumentCount] = useState(0)
  const [spinner, setSpinner] = useState(false)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [userData, setUserData] = useState({})

  useEffect(() => {
    getAllTheme()
  }, [search, page])


  useEffect(() => {
    GetUser()
  }, [])

  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
        console.log(response.data.data, "olololol")
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }  
  const getAllTheme = async () => {
    setSpinner(true)
    let url = GetThemeEnd + `?page=${page}`
    if (search.length > 0) {
      url = GetThemeEnd + `?page=1` + `&name=${search}`
    }
    try {
      const res = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (res.status === 200) {
        const parsedData = (res?.data?.data);
        setAllThemes(parsedData)
        setDocumentCount(res?.data?.total_document)
        setPage(res?.data?.page)
        setSpinner(false)
      } else throw new Error(res?.data?.msg || 'Try again later!')
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong. Try again later!'
      )
      setSpinner(false)
    }
  }

  let items = []
  let totalPage = 0
  if (documentCount < 10) totalPage = 1
  else totalPage = Math.ceil(documentCount / 10)
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number == page}>
        {number}
      </Pagination.Item>
    )
  }

  return (
    <div className='row py-3'>
      {/* <div className='col-3'>
        <DashBoard />
      </div> */}
      <div className='col-12'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h3 className='fw-bold'>Theme Management</h3>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => setShow(true)}
          >
            <img className='me-3' src={plus} alt='' /> Create New Theme
          </button>
        </div>
        <div className='custom-input w-100 me-2'>
          <label htmlFor=''><b>Search Theme</b></label>
          <br />
          <div style={{ position: 'relative' }}>
            <input
              type='text'
              placeholder='Search theme by name'
              onChange={(e) => setSearch(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
            />
          </div>
        </div>

        <div className='d-block'>
          {spinner ? (
            <div className='text-center my-5'>
              <Spinner animation='border' className='text-success' />
            </div>
          ) : allThemes?.length > 0 ? (
            allThemes.map((theme, idx) => (
              <ThemeCard
                theme={theme}
                key={idx}
                getAllTheme={() => getAllTheme()}
              />
            ))
          ) : (
            <h2 className='text-center text-muted my-5 py-5'>
              No Theme Found!
            </h2>
          )}
        </div>

        {!spinner && allThemes?.length > 0 && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Pagination
              onClick={(e) => {
                setPage(e.target.innerText)
              }}
            >
              {items}
            </Pagination>
          </div>
        )}
      </div>

      <CreateThemeModal
        userData={userData}
        show={show}
        handleClose={handleClose}
        getAllTheme={() => getAllTheme()}
      />
    </div>
  )
}

export default ThemeManagement
