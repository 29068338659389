import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { AdEditEnd, FileUploadEnd, GetScreen } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import instock from '../../../assets/images/instock.jpg'
import Select from 'react-select';

import outstock from '../../../assets/images/stock out-stock.jpg'
import ReactPlayer from 'react-player'
import Frame from '../../../assets/images/Frame.png'
import ScrnImg from '../../../assets/images/screen-img.png'
import demoImg from '../../../assets/images/demoLogoImg.png'
import { screenId } from '../../../constants/const'
const EditAdModal = ({ show, handleClose, ad, loadAllFolders, folderID }) => {
  const [spinner, setSpinner] = useState(false)
  const [productData, setProductData] = useState([])
  const [photoSpinnerOutofstock, setPhotoSpinnerOutofstock] = useState(false)
  const [photoSpinnerInstock, setPhotoSpinnerInstock] = useState(false)
  const [productDataSpin, setproductDataSpin] = useState(false)
  const MAX_FILE_SIZE_MB = 4; // Set the maximum file size limit in megabytes

  const HTTP = {
    OK: 200
  }
  const [data, setData] = useState({
    folder_id: '',
    name: '',
    description: '',
    link: '',
    type: 'photo',
    outofstock: 'photo',
    noStock: 'photo',
    typestock: '',
    product: ''
  })
  const Https = {
    OK: 200
  }
  useEffect(() => {
    setData({
      folder_id: ad?.folder_id?._id || '',
      name: ad?.name,
      link: ad?.link,
      description: ad?.description,
      type: ad?.type,
      outofstock: ad?.outofstock,
      noStock: ad?.noStock,
      typestock: ad?.typestock,
      product: ad?.product
    })
  }, [ad, handleClose])

  const handleImageUpload = async (e) => {
    // setPhotoSpinner(true)
    if (!e.target.files || e.target.files.length === 0) {
      setData({ ...data, link: demoImg });
      return;
    }
    const file = e.target.files[0]
    const fileType = file.type;
    const formData = new FormData()
    formData.append('files', file)

    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === Https.OK) {
        setData({ ...data, link: res.data?.files[0]?.path });
        Toast('success', 'File uploaded successfully')
      }
    } catch (error) {
      setData({ ...data, link: null });
    }
  }

  const handleImageOutofstock = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setData({ ...data, outofstock:  outstock});
      return;
    }
    setPhotoSpinnerOutofstock(true)
    const file = e.target.files[0]

    const formData = new FormData()
    formData.append('files', file)

    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === HTTP.OK) {
        setData({
          ...data,
          outofstock: res.data?.files[0]?.path,
        })
        setPhotoSpinnerOutofstock(false)
        Toast('success', 'File uploaded successfully')
      }
    } catch (error) {
      setPhotoSpinnerOutofstock(false)
    }
  }
  const handleImageInstock = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setData({ ...data, noStock:  instock});
      return;
    }
    setPhotoSpinnerInstock(true)
    const file = e.target.files[0]

    const formData = new FormData()
    formData.append('files', file)

    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === HTTP.OK) {
        setData({
          ...data,
          noStock: res.data?.files[0]?.path,
        })
        setPhotoSpinnerInstock(false)
        Toast('success', 'File uploaded successfully')
      }
    } catch (error) {
      setPhotoSpinnerInstock(false)
    }
  }


  useEffect(() => {
    loadProductData();
  }, []);
  const loadProductData = async () => {
    // setSpinner(true);
    setproductDataSpin(true)
    try {
      const response = await axios.get(GetScreen + `/${screenId}` + '/get_products', {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      const temp = response.data.data.product;
      setProductData(temp);
      setproductDataSpin(false)
      // setSpinner(false);

    } catch (error) {
      // setSpinner(false)
      setproductDataSpin(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const handleEditAd = async () => {
    setSpinner(true)
    if (!data?.link) {
      Toast('err', 'Medial file must be provided')
      setSpinner(false)
      return
    }
    if (!data?.name) {
      Toast('err', 'Please insert folder name')
      setSpinner(false)
      return
    }
    if (!data?.description) {
      Toast('err', 'Please insert folder description')
      setSpinner(false)
      return
    }
    if (data?.typestock == "instock") {
      if (!data?.noStock) {
        Toast('err', 'In Stock image must be provided ')
        setSpinner(false)
        return
      }
      if (!data?.outofstock) {
        Toast('err', 'Out of Stock image must be provided  ')
        setSpinner(false)
        return
      }
      if (!data?.product) {
        Toast('err', 'please select product')
        setSpinner(false)
        return
      }

    }
    if (!data?.type) {
      Toast('err', 'Type must be provided')
      setSpinner(false)
      return
    }

    try {
      const response = await axios.put(
        AdEditEnd,
        { id: ad?._id, ...data },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'AD Updated!')
        handleClose()
        setSpinner(false)
        setData({
          folder_id: '',
          name: '',
          description: '',
          link: '',
          type: 'photo',
        })
        if (folderID) {
          loadAllFolders(folderID._id)
        }
        else {
          loadAllFolders(folderID)
        }
        // loadAllFolders(folderID)
      } else throw new Error(response.data?.msg || 'Try again later')
    } catch (error) {
      setSpinner(false)

      Toast('err', error.response?.data?.msg)
    }
  }
  return (
    <Modal show={show} onHide={() => { handleClose() }} size='lg'>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title style={{ fontSize: '22px' }}>Edit AD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-end' >
          {data?.type === 'photo' ? (
            <img
              src={data?.link?.includes('.mp4') ? demoImg : data?.link ?? demoImg}
              alt=''
              height='100'
              width='200'
              className='me-4'
            />
          ) : (
            <div className='mx-3'>
              <ReactPlayer
                url={data?.link}
                width='200px'
                height='100px'
                controls={true}
              />
            </div>
          )}

          <Form.Group className='' controlId='formBasicEmail'>
            <Form.Label>
              {data?.type}*
              {/* {photoSpinner && (
              <Spinner className='ms-1' animation='border' size='sm' />
              )} */}
            </Form.Label>
            <Form.Control type='file' onChange={(e) => handleImageUpload(e)} />
          </Form.Group>
        </div>
        {
          data?.typestock == "instock" && (
            <>
              <h4 className='m-4'>In  Stock*</h4>
              <div className='d-flex justify-content-start align-items-end'>
                {data?.type === 'photo' ? (
                  <img
                    src={data?.noStock || instock}
                    alt=''
                    height='100'
                    width='200'
                    className='me-4'
                  />
                ) : (
                  <div className='mx-3'>
                    <ReactPlayer
                      url={data?.noStock || instock}
                      width='200px'
                      height='100px'
                      controls={true}
                    />
                  </div>
                )}

                <Form.Group className='' controlId='formBasicEmail'>
                  <Form.Label>
                    {data?.type}*
                    {photoSpinnerInstock && (
                      <Spinner className='ms-1' animation='border' size='sm' />
                    )}
                  </Form.Label>
                  <Form.Control type='file' onChange={(e) => handleImageInstock(e)} />
                </Form.Group>
              </div>
              <h4 className='m-4'>Out of Stock*</h4>
              <div className='d-flex justify-content-start align-items-end'>
                {data?.type === 'photo' ? (
                  <img
                    src={data?.outofstock || outstock}
                    alt=''
                    height='100'
                    width='200'
                    className='me-4'
                  />
                ) : (
                  <div className='mx-3'>
                    <ReactPlayer
                      url={data?.outofstock || outstock}
                      width='200px'
                      height='100px'
                      controls={true}
                    />
                  </div>
                )}

                <Form.Group className='' controlId='formBasicEmail'>
                  <Form.Label>
                    {data?.type}*
                    {photoSpinnerOutofstock && (
                      <Spinner className='ms-1' animation='border' size='sm' />
                    )}
                  </Form.Label>
                  <Form.Control type='file' onChange={(e) => handleImageOutofstock(e)} />
                </Form.Group>
              </div>
            </>
          )
        }
        <div className='my-3'>
          <div className='plain-input my-3'>
            <label for=''>Ad Name* </label>
            <br />
            <input
              type='text'
              placeholder='Enter name'
              value={data?.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Description</label>
            <br />
            <input
              type='text'
              placeholder='Enter details'
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </div>

          <div className='plain-dropdown my-3'>
            <label for=''>File Type*</label>
            <br />
            <select
              className=''
              onChange={(e) => setData({ ...data, type: e.target.value })}
              value={data?.type}
            >
              <option value='photo' >
                Photo
              </option>
              <option value='video'>Video</option>
            </select>
          </div>
          {
            data?.typestock === "instock" && (
              <div className='plain-dropdown my-3'>
                <label htmlFor=''>Products</label>
                <br />
                {
                  !productDataSpin ? (
                    <Select
                      options={productData.flat().map(product => ({ value: product.id, label: product.name }))}
                      value={productData.flat().map(product => {
                        if (product.id === data.product) {
                          return { value: product.id, label: product.name }
                        }
                      })} // Set initial selected value
                      onChange={(selectedOption) => setData({ ...data, product: selectedOption.value })}
                      isSearchable
                    />
                  ) : (
                    <Spinner
                      animation='border'
                      style={{ color: 'var(--primary_color)' }}
                    />
                  )
                }

              </div>
            )
          }


          <div class='plain-dropdown my-3'>
            <label for='' style={{ fontSize: "20px" }}>Select Stock*</label>
            <br />
            <div class="checkbox-container">
              <input
                id="instock-checkbox"
                type="checkbox"
                size={30}
                className="checkbox-input"
                checked={data?.typestock === "instock"} // Add this line to set the checked state based on typestock

                onChange={(e) => setData({ ...data, typestock: e.target.checked ? 'instock' : 'outofstock' })}
              />

              <label for="instock-checkbox" class="checkbox-label">Daily Base</label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className='primary-btn-light' onClick={handleClose}>
          Close
        </button>
        <button className='primary-btn' disabled={spinner} onClick={() => handleEditAd()}>
          Save Changes{' '}
          {spinner && <Spinner animation='border' size='sm' className='ms-2' />}
        </button>

      </Modal.Footer>
    </Modal>

  )
}

export default EditAdModal
