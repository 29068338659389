



import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import { Maxlength, length } from '../../../constants/const'

import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'
import demoImg from '../../../assets/images/demoLogoImg.png'
import {
    CompanyEditEnd,
    DeleteStoreEnd,
    FileUploadEnd,
    StoreEdit,
    CompanyDeleteEnd
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import { PHONE_REGEX, phoneRegex, phone_Regex, punctuationRegex, spaceRegex, wordBoundaryRegex } from '../../../constants/regex'
import { handleImageUpload, postCompanyData } from "../../../constants/apifunction"
import { resultMs } from '../../../constants/const'
const EditCompanyModal = ({ show, handleClose, data, loadStoreData }) => {
    const history = useHistory()
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteSpinner, setDeleteSpinner] = useState(false)
    const allowedExtensions = ["mp4", "webp", "mkv", "gif"];
    const [disabled, setDisabled] = useState(false);
    const [firstTaskCompleted, setFirstTaskCompleted] = useState(false);

    const [editSpinner, setEditSpinner] = useState(false)
    const [companyData, setCompanyData] = useState({
        name: '',
        company_name: '',
        company_email: '',
        company_phone: '',
        company_logo: '',

    })
    // const phoneRegex = PHONE_REGEX;

    const [photoSpinner, setPhotoSpinner] = useState(false)
    const [tag, setTag] = useState('')
    const [rTag, setrTag] = useState('')

    const [originalData, setOriginalData] = useState({}); // State to hold the original company data

    useEffect(() => {
        setCompanyData({
            name: data?.name,
            company_name: data?.company_name,
            company_email: data?.company_email,
            company_phone: data?.company_phone,
            company_logo: data?.company_logo,
        });
        setOriginalData({
            name: data?.name,
            company_name: data?.company_name,
            company_email: data?.company_email,
            company_phone: data?.company_phone,
            company_logo: data?.company_logo,
        });
    }, [data]);
    const debounceFn = useCallback(debounce(postStoreData, resultMs), []);


    const handleInputChange = () => {

        debounceFn(companyData)
        // debounce(loadTicketData, 2000)
        // loadTicketData()
        // debouncedOnChange(sanitizedValue);
    };



    const handleInput = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;

        if (name === 'name' || name === 'company_name') {
            sanitizedValue = sanitizedValue.replace(wordBoundaryRegex, ' '); 
            sanitizedValue = sanitizedValue.replace(punctuationRegex, '$1 '); 
          }

        // Update the state
        setCompanyData({ ...companyData, [name]: sanitizedValue });
    };



    async function postStoreData() {
        const trimmedName = companyData.name.trim();
        const trimmedCompanyName = companyData.company_name.trim();

        setFirstTaskCompleted(true);
        // Check length without spaces
        if (trimmedName === '') {
            Toast('err', 'Please enter company name');
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (trimmedName.length < length) {
            Toast('err', `Company name must be at least ${length} characters long.`);
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (trimmedName.length > 25) {
            Toast('err', `Company name must be 25 characters.`);
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (trimmedCompanyName === '') {
            Toast('err', 'Please enter manager name');
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (trimmedCompanyName.length < length) {
            Toast('err', `Company manager name must be at least ${length} characters long.`);
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (trimmedCompanyName.length > Maxlength) {
            Toast('err', `Company manager name must be ${Maxlength} characters.`);
            setFirstTaskCompleted(false);
            setEditSpinner(false);
            return;
        }
        if (companyData.company_email.trim() === '') {
            Toast('err', 'Please enter company manager email');
            setFirstTaskCompleted(false)
            setEditSpinner(false);
            return;
        }
        if (companyData.company_phone.trim() === '') {
            Toast('err', 'Please enter your company phone');
            setFirstTaskCompleted(false)
            setEditSpinner(false);
            return;
        }
        if (!phoneRegex.test(companyData.company_phone)) {
            Toast('err', 'Invalid phone number');
            setFirstTaskCompleted(false)
            setEditSpinner(false);
            return;
        }
        setEditSpinner(true);
        try {
            const result = postCompanyData(companyData, data);
            result.then((response) => {
                if (response.status === 200) {
                    setEditSpinner(false);
                    Toast('success', 'Company updated!');
                    handleClose();
                    loadStoreData();
                    setCompanyData({
                        name: '',
                        company_name: '',
                        company_email: '',
                        company_phone: '',
                        company_logo: '',
                    });
                } else {
                    throw new Error(response?.data?.msg);
                }
            });
        } catch (error) {
            setEditSpinner(false);
            Toast(
                'err',
                error.response?.data?.msg || 'Something went wrong! Try again later.'
            );
        }
        finally {

            setTimeout(() => {
                // Re-enable the function after some time (e.g., 1 second)
                setFirstTaskCompleted(false)
            }, 3000);
        }
    }



    const handleImageUploadWrapper = async (e) => {



        await handleImageUpload(e.target.files[0], companyData, setCompanyData, setPhotoSpinner);
    };
    const handleCloseReset = () => {
        setCompanyData(originalData); // Reset to original data when closing without saving
        handleClose();
    };




    const handleDelete = async () => {
        setDeleteSpinner(true)
        try {
            const res = await axios.delete(CompanyDeleteEnd + `?_id=${data?._id}`, {
                headers: {
                    menuboard: localStorage.getItem('menu_token'),
                },
            })
            if (res.status === 200) {
                Toast('success', 'Company has been deleted successfully')
                setDeleteModal(false)
                setDeleteSpinner(false)
                history.push('/company-management')
            } else throw new Error(res?.data?.msg)
        } catch (error) {
            Toast('err', error.response?.data?.msg)
            setDeleteModal(false)
            setDeleteSpinner(false)
        }
    }

    return (
        <>
            <Modal show={show} onHide={() => { handleClose(); handleCloseReset() }} size='lg'>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title style={{ fontSize: '22px' }}>Edit Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Company Brand Icon / Logo</h6>
                    <div className='d-flex justify-content-start align-items-end'>
                        <img
                            src={companyData?.company_logo || demoImg}
                            alt='demoImg'
                            height='100'
                            width='100'
                            className='me-4'
                        />

                        <Form.Group className='' controlId='formBasicEmail'>
                            <Form.Label>
                                <strong>Image </strong> (aspect ratio should be 1:1. e.g. 512px
                                x 512px)
                                {photoSpinner && (
                                    <Spinner className='ms-1' animation='border' size='sm' />
                                )}
                            </Form.Label>
                            <Form.Control
                                type='file'
                                onChange={(e) => handleImageUploadWrapper(e)}
                            />
                        </Form.Group>
                    </div>
                    <div className='plain-input my-3'>
                        <label for=''>Company Name*</label>
                        <br />
                        <input
                            type='text'
                            placeholder='Enter  company name'
                            value={companyData.name}
                            onChange={handleInput}
                            name='name'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    postStoreData();
                                }
                            }}
                        />
                    </div>
                    <div className='plain-input my-3'>
                        <label for=''>Company Manager / POC*</label>
                        <br />
                        <input
                            type='text'
                            placeholder='Enter name of company manager'
                            value={companyData.company_name}
                            onChange={handleInput}
                            name='company_name'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    postStoreData();
                                }
                            }}
                        />
                    </div>
                    <div className='plain-input my-3'>
                        <label for=''>Company manager
                            Email*</label>
                        <br />
                        <input
                            type='text'
                            placeholder='Enter company manager email'
                            disabled
                            value={companyData.company_email}
                            onChange={handleInput}
                            name='company_email'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    postStoreData();
                                }
                            }}
                        />
                    </div>

                    <div className='plain-input my-3'>
                        <label for=''>Company Manager Phone*</label>
                        <br />
                        <input
                            type='test'
                            placeholder='+1 (123) 456-7890'
                            value={companyData.company_phone}
                            onChange={(e) => {
                                // Remove non-numeric characters except '+'
                                const sanitizedValue = e.target.value.replace(phone_Regex, '');
                                setCompanyData({ ...companyData, company_phone: sanitizedValue });
                            }}
                            name='company_phone'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    postStoreData();
                                }
                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='danger-btn-light'
                        onClick={() => {
                            handleClose()

                            setDeleteModal(true)
                        }}
                    >
                        Delete
                    </button>
                    <button className='primary-btn-light' onClick={() => { handleClose(); handleCloseReset() }} >
                        Close
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={postStoreData}
                        type='submit'
                        disabled={disabled || firstTaskCompleted} // Disable if either first task completed or already disabled
                    >
                        Save Changes{' '}
                        {editSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <h4 className='fw-bold text-danger'>Caution!</h4>
                    <h5>You are going to delete this company which is irreversible.</h5>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none' }}>
                    <button
                        className='primary-btn-light'

                        onClick={() => setDeleteModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => handleDelete()}
                        type='submit'
                        disabled={deleteSpinner}
                    >
                        Delete{' '}
                        {deleteSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditCompanyModal