import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Dropdown, Pagination, Spinner, Breadcrumb } from 'react-bootstrap'
import { AiFillFolder, AiFillFolderAdd } from 'react-icons/ai'
import { FaArrowLeft } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import plus from '../../assets/icons/plus.svg'
import threedot from '../../assets/icons/threedot.svg'
import { AdCards } from '../../components/AdCards'
import DashBoard from '../../components/DashBoard/DashBoard'
import { AddNewAdModal } from '../../components/Modals/AddNewAdModal'
import AddNewFolderModal from '../../components/Modals/AddNewFolderModal/AddNewFolderModal'
import DeleteFolderModal from '../../components/Modals/DeleteFolderModal/DeleteFolderModal'
import EditFolderModal from '../../components/Modals/EditFolderModal/EditFolderModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AdGetEnd, AdGetEndFolder, GetAdminProfileUrl, GetAllFoldersEnd, GetScreenEnd, StoreAPI, DeleteAdEnd } from '../../constants/api.constants'
import { Roles } from '../../constants/roles'
import detectAdBlock from '../../utils/DetectAdBlocker/DetectAdBlocker'
import Toast from '../../utils/Toast/Toast'
import './AdManagement.scss'
import { EditCard } from '../../components/Modals/EditCard'
import StoreFront from '../StoreFront/StoreFront'

const AdManagement = () => {
  let history = useHistory()
  const [show, setShow] = useState(false)
  const [allFolders, setAllFolders] = useState([])
  const [folderSearchId, setFolderSearchId] = useState('')
  const [previousSearchId, setPreviousSearchId] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [ad, setAd] = useState([])
  const [view, setView] = useState('grid')
  const [adSearchKey, setAdSearchKey] = useState('')
  const [addNewFolder, setAddNewFolder] = useState(false)
  const [editFolder, setEditFolder] = useState(false)
  const [editFolderDetails, setEditFolderDetails] = useState({})
  const [deleteFolder, setDeleteFolder] = useState(false)
  const [deleteFolderDetails, setDeleteFolderDetails] = useState({})
  const [folderName, setFoldername] = useState('')
  const [storeData, setStoreData] = useState({})
  const [userData, setUserData] = useState({})
  const [page, setPage] = useState(1)
  const [totalDoc, setTotalDoc] = useState(0)
  const [folderPath, setFolderPath] = useState([]); // State to maintain the folder path

  const [pagefolder, setpagefolder] = useState(1)
  const params = useParams()

  const HTTP = {
    OK: 200
  }

  useEffect(() => {
    GetUser()
  }, [])

  useEffect(() => {
    if (!params?.folderId) {
      setPreviousSearchId('')
      setFolderSearchId('')
    }
  }, [params?.folderId])

  useEffect(() => {
    detectAdBlock()

    if (view === 'grid') {
      loadAllFolders(folderSearchId)
    } else {
      // loadAllAds()
    }
  }, [folderSearchId, view, pagefolder])

  useEffect(() => {
    loadAllAds()
  }, [adSearchKey, page])

  const loadAllFolders = async (id, page) => {
    setSpinner(true)
    try {
      let url = GetAllFoldersEnd
      if (folderSearchId) {
        url += `?parent_id=${id || id._id}`
      }

      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === HTTP.OK) {
        const parseData = response.data.data
        setAllFolders(parseData)
      } else {
        throw new Error(response?.data?.msg || 'Cannot load folders at this time. Please try again later.')
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }

    if (folderSearchId || id) {
      if (page) {
        setpagefolder(page)
      }
      try {
        let adGetUrl = AdGetEndFolder + `?pagefolder=${Number(page ? page : pagefolder)}`;
        if (folderSearchId) {
          adGetUrl += `&folder_id=${folderSearchId}`;
        }

        const response = await axios.get(adGetUrl, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });

        if (response.status === HTTP.OK) {
          setAd(response.data.data);
          setSpinner(false);
          setTotalDoc(response.data.total_document)
        } else {
          throw new Error(response?.data?.msg || 'Cannot load ads at this time. Please try again later.');
        }
      } catch (error) {
        Toast('err', error.response?.data?.msg)
        setSpinner(false)
      }
    }
    else {
      loadAllAds()
    }
  }

  useEffect(() => {
    setPage(1);
  }, [folderSearchId]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPage(1);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (userData?.store)
      loadStoreData()
  }, [userData])

  const loadStoreData = async () => {
    const store_Id = userData?.store;

    setSpinner(true);

    try {
      const response = await axios.get(StoreAPI + `?id=${store_Id}`, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      });

      if (response.status === 200) {
        const temp = JSON.parse(response.data.data);
        setStoreData(temp[0]);
        setSpinner(false);
      } else {
        throw new Error(response?.data?.msg || 'Something went wrong! Try again later.');
      }
    } catch (error) {
      setSpinner(false);
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      );
    }
  };

  const loadAllAds = async () => {
    setFolderPath([])
    setSpinner(true)
    try {
      let adGetUrl = AdGetEnd + `?page=${page}`;

      if (adSearchKey) {
        adGetUrl += `&name=${adSearchKey}`;
        setView('list');
      }

      const response = await axios.get(adGetUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (!response.data.data.length) {
        if (page > 1) {
          setPage(page - 1);
          return;
        }
      }

      if (response.status === 200) {
        setAd(response.data.data)
        setTotalDoc(response.data.total_document)
        setSpinner(false)
      } else {
        throw new Error(response?.data?.msg || 'Cannot load ads at this time. Please try again later.');
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg)
      setSpinner(false)
    }
  }

  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response.data.data)
      } else {
        throw new Error(response?.data?.msg)
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }

  const handleEditFolder = (folder) => {
    setEditFolderDetails(folder)
    setEditFolder(true)
  }

  const handleDeleteFolder = (folder) => {
    setDeleteFolderDetails(folder)
    setDeleteFolder(true)
  }
  const handleFolderClick = (folderId, folderName) => {
    const newPath = [...folderPath, { id: folderId, name: folderName }];
    setFolderPath(newPath);
    history.push(`/ad-management/${folderId}`);
  };

  // Function to handle breadcrumb click
  const handleBreadcrumbClick = (index) => {
    const newPath = folderPath.slice(0, index + 1);
    setFolderPath(newPath);
    const folderId = index === -1 ? '' : folderPath[index].id;
    history.push(`/ad-management/${folderId}`);
    if (folderId) {
      loadAllFolders(folderId)
    }
  };


  let items = []
  let totalPage = Math.ceil(totalDoc / 10)
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === page}>
        {number}
      </Pagination.Item>
    )
  }

  const startIndex = (page - 1) * 10 + 1;

  return (
    <div className='row py-3'>
      <div className='col-12'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fw-bold'>Advertisement Management</h3>
          <button
            className='primary-btn d-flex justify-content-center align-items-center'
            onClick={() => setShow(true)}
          >
            <img className='me-3' src={plus} alt='' /> Upload New Ad
          </button>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <div className='custom-input me-2'>
            <label htmlFor=''><b>Search Adverts</b></label>
            <br />
            <div style={{ position: 'relative' }}>
              <input
                type='text'
                placeholder='Search by name etc'
                onChange={(e) => setAdSearchKey(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
              />
            </div>
          </div>

          <div className='custom-dropdown ms-2'>
            <label for=''>View</label>
            <select onChange={(e) => setView(e.target.value)}>
              <option value='grid' selected={view === 'grid'}>
                Grid view
              </option>
              <option value='list' selected={view === 'list'}>
                List View (all ad)
              </option>
            </select>
          </div>
        </div>
        {spinner && (
          <div className='text-center my-5'>
            <Spinner
              animation='border'
              style={{ color: 'var(--primary_color)' }}
            />
          </div>
        )}
        {!spinner && view === 'grid' && (
          <section>

            <Breadcrumb>
              <Breadcrumb.Item onClick={() => handleBreadcrumbClick(-1)}>Ad Management</Breadcrumb.Item>
              {folderPath.map((folder, index) => (
                <Breadcrumb.Item key={index} onClick={() => handleBreadcrumbClick(index)}>
                  {folder.name}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <section className='d-flex justify-content-start align-items-start flex-wrap'>
              {allFolders.length > 0 &&
                allFolders.map((folder, idx) => (
                  <div className='d-flex justify-content-start align-items-start flex-wrap'>
                    <div
                      key={idx}
                      className='all_fldr m-2 px-2 custom-folder d-flex justify-content-between align-items-start'
                      onClick={() => {
                        handleFolderClick(folder._id, folder.name)
                        setPreviousSearchId(folderSearchId)
                        setFolderSearchId(folder?._id)
                        setFoldername(folder?.name)
                        history.push(`/ad-management/${folder?._id || ''}`)
                        setpagefolder(1)
                      }}
                    >
                      <div>
                        <AiFillFolder
                          style={{
                            height: '3.5rem',
                            width: '3.5rem',
                          }}
                          color='#bad9ba'
                          title={folder?.name}
                        />
                        <p
                          className='fldr_name m-0 text-center'
                       
                        >
                          <small>{folder?.name}</small>
                        </p>
                      </div>

                    </div>
                    <Dropdown drop='end' className='drop_end' >
                      <Dropdown.Toggle
                        variant='transparent'
                        id='dropdown-basic'
                        style={{ cursor: 'pointer', paddingLeft: '0', outline: '0' }}
                      >
                        <img
                          src={threedot}
                          alt=''
                          style={{
                            transform: 'rotate(90deg)',
                            // display: 'none',
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className=''>
                        <Dropdown.Item onClick={() => handleEditFolder(folder)}>
                          Edit Folder
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDeleteFolder(folder)}>
                          Delete Folder
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ))}
              <div
                onClick={() => setAddNewFolder(true)}
                style={{ cursor: 'pointer' }}
                className='m-2 px-2'
              >
                <AiFillFolderAdd
                  style={{
                    height: '3.5rem',
                    width: '3.5rem',
                  }}
                  color='#bad9ba'
                  title='Add New Folder'
                />
              </div>
            </section>
            {(userData?.effective_role === Roles.SUPER_ADMIN || userData?.effective_role === Roles.ADMIN) && ad.length === 0 && (
              <h3 className='text-secondary my-5 text-center'>
                No ad Found!
              </h3>
            )}
            {ad.length > 0 && ad.map((ad, idx) => (
              <AdCards
                index={startIndex + idx}
                key={idx}
                ad={ad}
                loadAllFolders={loadAllFolders}
                folderId={folderSearchId}
                page={page}
                setPage={setPage}
                setpagefolder={setpagefolder}
                pagefolder={pagefolder}
              />
            ))}
          </section>
        )}
        {!spinner && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            {folderSearchId ? (
              ad.length == '' || (
                <Pagination>
                  {items.map((item, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === pagefolder}
                      onClick={() => setpagefolder(index + 1)}
                    // disabled={index + 1 === page} // Disable the current page
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )
            ) : (
              ad.length == '' || (
                <Pagination>
                  {items.map((item, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === page}
                      onClick={() => setPage(index + 1)}
                    // disabled={index + 1 === page} // Disable the current page
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )
            )}
          </div>
        )}
      </div>
      <AddNewAdModal
        show={show}
        handleClose={() => setShow()}
        folderId={folderSearchId}
        loadAllFolders={loadAllFolders}
        allAds={loadAllAds}
      />
      <AddNewFolderModal
        show={addNewFolder}
        handleClose={() => setAddNewFolder(false)}
        parent={folderSearchId}
        loadAllFolders={loadAllFolders}
      />
      <EditFolderModal
        show={editFolder}
        handleClose={() => setEditFolder(false)}
        details={editFolderDetails}
        loadAllFolders={loadAllFolders}
        parentID={folderSearchId}
      />
      <DeleteFolderModal
        show={deleteFolder}
        handleClose={() => setDeleteFolder(false)}
        folder={deleteFolderDetails}
        loadAllFolders={loadAllFolders}
        parentID={folderSearchId}
      />
    </div>
  )
}

export default AdManagement
