import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Modal, Spinner } from 'react-bootstrap'
import 'react-input-range/lib/css/index.css'
// import { StoreProvider } from '../../Providers'
import { useHistory, useParams } from 'react-router-dom'
import plus from '../../assets/icons/plus.svg'
import AdCard from '../../components/AdCard/AdCard'
import DashBoard from '../../components/DashBoard/DashBoard'
import Frame from '../../assets/images/Frame.png'
import AddStoreAd from '../../components/Modals/AddStoreAd/AddStoreAd'
import { CreateNewScreen } from '../../components/Modals/CreateNewScreen'
import { EditScheduleModal } from '../../components/Modals/EditScheduleModal'
import { EditStoreModal } from '../../components/Modals/EditStoreModal'
import Menues from '../../assets/images/menus.png'
import Screens from '../../components/Screens/Screens'
import { GetAdminProfileUrl, GetAllCompanyEnd, GetAllScreenAd, GetScreenEnd, StoreAPI } from '../../constants/api.constants'
import Toast from '../../utils/Toast/Toast'
import './companyfront.scss'
import { Roles } from '../../constants/roles'
import { EditCard } from '../../components/Modals/EditCard'
import ScrnImg from '../../assets/images/screen-img.png'
import EditCompanyModal from '../../components/Modals/EditCompanyModal/EditCompanyModal'
const CompanyFront = () => {
  const [showCreateScreen, setShowCreateScreen] = React.useState(false)

  const [editModal, setEditModal] = useState(false)
  const handleClose = () => setShowCreateScreen(false)
  let history = useHistory()
  const [spinner, setSpinner] = useState(false)
  const [show, setShow] = useState(false)
  const [screenSpinner, setScreenSpinner] = useState(false)
  const [screenAdSpinner, setScreenAdSpinner] = useState(false)
  const [essentialID, setEssentialID] = useState([])
  const [editInfoModal, setEditInfoModal] = useState(false)
  const [screenSearchKey, setScreenSearchKey] = useState('')
  const [adnewAdd, setAdnewAdd] = useState(false)
  const [userData, setUserData] = useState({})
  const { id } = useParams()
  const [companyData, setCompanyData] = useState({})
  const [AllscreenAd, setAllscreenAd] = useState([])
  const [screenUnique, setscreensUnique] = useState({})
  const handleShow = () => setShow(true)
  const handleClosed = () => setShow(false)

  const HttpStatus = {
    OK: 200,

  };
  useEffect(() => {
    loadStoreData()
  }, [])
  useEffect(() => {
    GetUser();
  }, []);

  const setIdFromLink = (link) => {
    const companyIdStartIndex = link.indexOf('company/') + 'company/'.length;
    const companyId = link.slice(companyIdStartIndex).substring(0, 4);
    const storeIdStartIndex = link.indexOf('location/') + 'location/'.length;
    const storeId = link.slice(storeIdStartIndex).substring(0, 4);
    setEssentialID({
      "companyId": companyId,
      "storeId": storeId,
    })
  };
  const loadStoreData = async () => {
    setSpinner(true)
    try {
      const response = await axios.get(GetAllCompanyEnd + `?id=${id}`, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        const temp = (response.data.data);

        setCompanyData(temp[0]);
        setSpinner(false)
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const GetUser = async () => {

    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === HttpStatus.OK) {
        setUserData(response?.data?.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }




  return (
    <div className='row py-3'>
      {/* <div className='col-3'>
        <DashBoard />
      </div> */}
      <div className='col-9'>
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => history.push('/company-management')}
          >
            Companyfront Management
          </Breadcrumb.Item>
          <Breadcrumb.Item active>companyfront</Breadcrumb.Item>
        </Breadcrumb>
        <div className='d-flex justify-content-between align-items-center'>
          {/* <h3 className='fw-bold'> {store[0].name} </h3> */}
        </div>
        <h5 className='mt-4 fw-bold d-flex justify-content-start align-items-center'>
          Company Details
          {spinner && <Spinner animation='border' size='sm' className='ms-2' />}
        </h5>

        <section className='store-details d-flex justify-content-between align-items-start'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='me-5 custom-header' style={{minWidth:'150px'}}>
              <h6>Company Logo</h6>
              <h6 className='' style={{marginTop:"128px"}}>Company name</h6>
              <h6>Company Manager / POC</h6>
              <h6>Company Manager Email</h6>
              <h6>Company Manager Phone</h6>
            </div>
            <div className='ms-5'>
              <div
                className='preview-bg '
                style={{ backgroundImage: ` url("${companyData?.company_logo || ScrnImg}")` }}
              >
                {/* <img src={screenImg} alt='' className='me-3' /> */}
                <img
                  src={Frame}
                  alt=''
                  className='sm-preview'
                  onClick={() => handleShow(true)}
                />
              </div>
              {/* <h6>
                <img src={companyData?.company_logo} style={{ width: "150px", height: "70px" }} />
              </h6> */}
              <h6 className='companydata'>{companyData?.name}</h6>
              <h6 className='companydata'>{companyData?.company_name}</h6>
              <h6 className='companydata'>{companyData?.company_email}</h6>
              <h6 className='companydata'>{companyData?.company_phone}</h6>
            
            </div>
          </div>
          <button
            className='primary-btn-light'
            onClick={() => setEditInfoModal(true)}
          >
            Edit Info
          </button>

        </section>
      </div>


      <EditCompanyModal
        show={editInfoModal}
        handleClose={() => setEditInfoModal()}
        data={companyData}
        loadStoreData={loadStoreData}
        essentialId={essentialID}
      />

      <Modal show={show} onHide={handleClosed} size='lg' centered>
        <img src={companyData?.company_logo || Menues} alt='' />
      </Modal>

    </div>
  )
}

export default CompanyFront
