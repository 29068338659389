import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import demoImg from '../../../assets/images/demoLogoImg.png'
import { GetScreen } from '../../../constants/api.constants'
import "./AddProductTagModal.css"
import {
  DeleteStoreEnd,
  FileUploadEnd,
  StoreEdit,
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";


const AddProductTagModal = ({ screenId, productId, show, handleClose, data, loadEmojiData, handleProductTag }) => {
  const history = useHistory()
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const [photoUrl, setPhotoUrl] = useState('')
  const [productTag, setProductTag] = useState({
    tag_value: "",
    tag_type: "text"
  })
  const [spinner, setSpinner] = useState(false)
  const [emoji, setEmoji] = useState();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  function onClick(emojiData: EmojiClickData, event: MouseEvent) {
    setSelectedEmoji(emojiData.unified);
    setProductTag({
      tag_value: emojiData.emoji,
      tag_type: 'emoji'
    })
  }
  const HTTP = {
    OK: 200
  }
  const TagTypes = {
    Emoji: 'emoji',
    GIF: 'gif',
    Text: 'text'
  };

  const options = [
    { label: 'Emoji', value: TagTypes.Emoji },
    { label: 'GIF', value: TagTypes.GIF },
    { label: 'Text', value: TagTypes.Text }
  ];

  const handleChange = (e) => {
    setProductTag({ ...productTag, tag_type: e.target.value });
  };
  const handleAddGIFTag = async (e) => {
    try {
      setPhotoSpinner(true)
      const file = e.target.files[0]
      // Extract file extension
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      // Check if the file extension corresponds to a GIF
      if (fileExtension.toLowerCase() !== 'gif' || file.type !== 'image/gif') {
        Toast('err', "Only GIF files are allowed");
        setPhotoSpinner(false)
        return
        // Handle the error or notify the user about the restriction
      }
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      };
      const response = await axios.post(GetScreen + `/${screenId}` + '/upload_gif/' + `${productId}`, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (response.status == 200) {
        setPhotoUrl(response.data.path)
        setProductTag({
          tag_type: 'gif',
          tag_value: response.data.path
        })
        Toast('success', 'Tag file Successfully uploaded')
        setPhotoSpinner(false)
      }

    } catch (error) {
      setPhotoSpinner(false);
      Toast('err', error.response?.data?.msg || 'GIF Upload Failed')
    }
  }
  useEffect(() => {
    setProductTag({
      tag_type: 'text',
      tag_value: ''
    })
  }, [])

  function handleInput(e) {
   
    setProductTag({
      ...productTag,
      [e.target.name]: e.target.value,
    })
  }

  async function postProductTag() {
    setSpinner(true)
    if (productTag.tag_value === '') {
      Toast('err', 'Please enter tag')
      setSpinner(false)
      return
    }
    try {
      const res = await axios.post(
        GetScreen + `/${screenId}` + '/product_tags/' + `${productId}`,
        productTag,
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (res.status === HTTP.OK) {
        // setSpinner(false)
        handleProductTag(productTag.tag_value, productTag.tag_type)
        Toast('success', 'Tag Updated Successfully')
        setSpinner(false)
        handleClose();
      }

    } catch (error) {
      setSpinner(false)
      Toast('err', error.response?.data?.msg || 'Try again later!')
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Add Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='plain-dropdown'
            >
              <label for=''>Tag Type : </label>
              <select onChange={handleChange} value={productTag.tag_type}>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

            </div>
            {productTag.tag_type === 'emoji' ? (
              <div className='emoji' >
                <div className='selected-emoji'>
                  {selectedEmoji ? (
                    <h4 >Your selected Emoji is : &nbsp; &nbsp;
                      <Emoji
                        unified={selectedEmoji}
                        emojiStyle={EmojiStyle.APPLE}
                        size={22}

                      />
                    </h4>
                  ) : (
                    <h4 >You haven't selected any emoji yet</h4>
                  )}

                </div>
                <EmojiPicker
                  onEmojiClick={onClick}
                  autoFocusSearch={false}

                  width="100%"
                />
              </div>
            ) : productTag.tag_type === 'gif' ? (
              <div className='mygif d-flex justify-content-start align-items-end'
              >
                <img
                  src={photoUrl || demoImg}
                  alt=''
                  height='100'
                  width='200'
                  className='me-4'
                />
                <Form.Group className='' style={{marginBottom:"25px"}} controlId='formBasicEmail'>
                  <Form.Label>
                    {/* {data?.type}* */}
                    {photoSpinner && (
                      <Spinner className='ms-1 '  animation='border' size='sm' />
                    )}
                  </Form.Label>
                  <Form.Control type='file' onChange={(e) => handleAddGIFTag(e)} />
                </Form.Group>
              </div>
            ) : (<div className='txt'>
              <div className='plain-input my-3'>
                <label for=''>Text</label>
                <br />
                <input
                  type='text'
                  placeholder='Input the text of your tag'
                  value={productTag.tag_value}
                  onChange={handleInput}
                  name='tag_value'
                />
              </div>
            </div>)}

          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className='primary-btn-light' onClick={handleClose}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postProductTag()}
            type='submit'
          >
            Save Changes{' '}
            {spinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddProductTagModal
