import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Spinner, Table, Form} from 'react-bootstrap'
import 'react-input-range/lib/css/index.css'
import { useHistory, useParams } from 'react-router-dom'
import TicketTags from '../../components/TicketTags/TicketTags'
import pcIcon from '../../assets/icons/pc-icon.svg'
import tvIcon from '../../assets/icons/tv-icon.svg'
import mobileIcon from '../../assets/icons/mobile-icon.svg'
import DashBoard from '../../components/DashBoard/DashBoard'
import Toast from '../../utils/Toast/Toast'
import EditTicketModal from '../../components/Modals/EditTicketModal/EditTicketModal'
import { TaskAPI, FileUploadEnd, GetAdminProfileUrl, GetAllStoresList } from '../../constants/api.constants'
import plus from '../../assets/icons/plus.svg'
import { showProgress,showPriority,showDate } from '../TicketingPanel/TicketingPanel'
import AddSubTaskModal from '../../components/Modals/AddSubTask/AddSubtaskModal'
import EditSubTicketModal from '../../components/Modals/EditSubTicketModal/EditSubTicketModal'
import Comments from '../../components/Comments/Comments'
import "./TicketPage.scss"

function TicketPage() {
  const history = useHistory();
  const { id } = useParams();
  const [header,setHeader] = useState({});
  const [editSubTaskModal, setEditSubTaskModal] = useState(false);
  const [subTaskData,setSubTaskData] = useState({})
  const [ticketImages,setTicketImages] = useState([]);
  const [editTicketModal, setEditTicketModal] = useState(false);
  const [showSubTaskModal,setShowSubTaskModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userData, setUserData] = useState({})
  const [photoSpinner,setPhotoSpinner] = useState(false);
  const [subTaskSpinner, setSubTaskSpinner] = useState(false);
  // const [showAddSubTask, setShowAddSubTask] = useState(false);
  const [subTasks, setSubTasks] = useState([]);
  const [tags,setTags] = useState([]);
  
  const [isHovered, setIsHovered] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [comment, setComment] = useState();
  const [postComment, setPostComment] = useState({
    'author': 'Wadith',
    'text': '',
  })
  const [commentData, setCommentData] = useState([]);
  

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    // console.log(comment);
  };

  const handleSubmit = async() => {
    if (comment.trim() !== '') {
      try{
        // console.log(comment);
        if(localStorage.getItem('menu_token')){
          const response = await axios.post(TaskAPI +`/${id}/comments`,{
            'author': userData.name,
            'text':comment,
          }, {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          })
          // console.log(response.data.data);
          
          if(response.status == 200){
            Toast('success', "Comment Added Successfully");
            loadComments();
            setComment('');
          }
        }else{
          const response = await axios.post(TaskAPI +`/${id}/comments`,{
            'author': userData.name,
            'text':comment,
          }, {
            headers: {
              menuboard: localStorage.getItem('store_token'),
            },
          })
          // console.log(response.data.data);
          
          if(response.status == 200){
            Toast('success', "Comment Added Successfully");
            loadComments();
            setComment('');
          }
        }
        
      }catch(err){
        Toast('err',err.response?.data?.msg || 'Something went wrong! Try again later.')
      }
    }else{
      Toast('err',"Comments can't be empty")
    }
  };

  // const toggleHover = () => {
  //   setIsHovered(!isHovered);
  // };

  // const togglePreview = () => {
  //   setIsPreviewOpen(!isPreviewOpen);
  // };
  // console.log(useParams());
  // const logID = id;
  // var ticket = tickets.find(item=>item.id==id);
  const [ticket,setTicket] = useState({});
  const setActualHeader = () =>{
      if(localStorage.getItem("menu_token")){
        setHeader({
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        })
      }
      else if(localStorage.getItem("store_token")){
        setHeader({
          headers: {
            menuboard: localStorage.getItem('store_token'),
          },
        })
      }
  }
  useEffect(() => {
    setActualHeader();
    loadTicketData();
    loadTicketImages();
    loadComments();
    getUserInfo();
    // console.log(localStorage.getItem('menu_token'));
  }, []);
  // useEffect(()=>{
  //   loadTicketImages()
  // }, []);
  const getUserInfo = async () => {
    // setLoadInfoSpinner(true)
    if(localStorage.getItem('menu_token')){
      try {
        const response = await axios.get(GetAdminProfileUrl, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        })
  
        if (response.status === 200) {
          setUserData(response.data.data)
          // setLoadInfoSpinner(false)
        } else throw new Error(response?.data?.msg)
      } catch (error) {
        // setLoadInfoSpinner(false)
        Toast('err', error.response?.data?.msg)
      }
    }else if(localStorage.getItem('store_token')){
      const storeData = JSON.parse(localStorage.getItem('store_info'))
      setUserData({
        "name": storeData?.manager,
      })
    }else{
      setUserData({
        "name": 'Author'
      })
    }
    
  }
  const hiddenFileInput = React.useRef(null);
  const uploadPhotoAPI = async(formData) => {  
    try{
      const response = await axios.post(TaskAPI +`/${id}/pictures`,formData, header)
      console.log(response.data.data);
      if(response.status == 200){
        setPhotoSpinner(false);
        Toast('success', "Photo Uploaded Successfully");
        loadTicketImages();
      }
      // if(localStorage.getItem('menu_token')){
      //   const response = await axios.post(TaskAPI +`/${id}/pictures`,formData, {
      //     headers: {
      //       menuboard: localStorage.getItem('menu_token'),
      //     },
      //   })
      //   console.log(response.data.data);
      //   if(response.status == 200){
      //     Toast('success', "Photo Uploaded Successfully");
      //     loadTicketImages();
      //   }
      // }else{
      //   const response = await axios.post(TaskAPI +`/${id}/pictures`,formData, {
      //     headers: {
      //       menuboard: localStorage.getItem('store_token'),
      //     },
      //   })
      //   console.log(response.data.data);
      //   if(response.status == 200){
      //     Toast('success', "Photo Uploaded Successfully");
      //     loadTicketImages();
      //   }
      // }
        
    }
    catch(error){
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
    
  }
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleImageUpload = async (event) => {
    // setPhotoSpinner(true);
    const files = Array.from(event.target.files);
    if(files.length + ticketImages.length > 5){
      Toast(
        'err',
        'You can not add more than 5 photos'
      )
      // setPhotoSpinner(false);
      return;
    }else{
        files.forEach(file => {
            const formData = new FormData();
            formData.append('file',file)
            uploadPhotoAPI(formData);
            
        });
        // setPhotoSpinner(false);      
    }
    
  };
  const deleteTicketImage = async (imageID) => {
    // setPhotoSpinner(true);
    try{
      const response = await axios.delete(TaskAPI +`/${id}/pictures/${imageID}`,header)
      if(response.status == 200){
        Toast('success', "Image Deleted Successfully")
        loadTicketImages()
        // setPhotoSpinner(false);
      }
    } catch(error){
      Toast('err',error.response?.data?.msg || 'Something went wrong! Try again later.')
      // setPhotoSpinner(false);
    }
  }
const loadTicketImages = async () => {
  setPhotoSpinner(true);
  try {
    if(localStorage.getItem('menu_token')){
      const response = await axios.get(TaskAPI +`/${id}/pictures`,{
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      // console.log(response.data.data[0].subtask);
      if(response.status === 200){
        // console.log(response.data.data[0].pictures);
        setTicketImages(response.data.data[0].pictures)
        setPhotoSpinner(false);
      }
    }else{
      const response = await axios.get(TaskAPI +`/${id}/pictures`,{
        headers: {
          menuboard: localStorage.getItem('store_token'),
        },
      })
      // console.log(response.data.data[0].subtask);
      if(response.status === 200){
        // console.log(response.data.data[0].pictures);
        setTicketImages(response.data.data[0].pictures)
        setPhotoSpinner(false);
      }
    }
    
    // else throw new Error(
    //   response?.data?.msg || ' Something went wrong! Try again later.'
    // )
 } catch(error){
    setSubTaskSpinner(false)
    Toast(
      'err',
      error.response?.data?.msg || 'Something went wrong! Try again later.'
    )
    setPhotoSpinner(false);
 }
}
const loadSubTaskData = async () => {
   setSubTaskSpinner(true);
   try {
      const response = await axios.get(TaskAPI +`/${id}/subtasks`,{
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      // console.log(response.data.data[0].subtask);
      if(response.status === 200){
        setSubTasks(response.data.data[0].subtask)
        setSubTaskSpinner(false);
      }else throw new Error(
        response?.data?.msg || ' Something went wrong! Try again later.'
      )
   } catch(error){
      setSubTaskSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
   }
}
const loadComments = async () => {
  try{
    if(localStorage.getItem('menu_token')){
      const response = await axios.get(TaskAPI + `/${id}/comments`,{
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if(response.status === 200){
        //  console.log(response.data.data[0].comments);
         setCommentData(response.data.data[0].comments);
      }
    }else{
      const response = await axios.get(TaskAPI + `/${id}/comments`,{
        headers: {
          menuboard: localStorage.getItem('store_token'),
        },
      })
      if(response.status === 200){
        //  console.log(response.data.data[0].comments);
         setCommentData(response.data.data[0].comments);
      }
    }
    
  }catch(err){
    console.log(err);
  }
}
const loadTicketData = async () => {
  setSpinner(true)
  try {
    if(localStorage.getItem('menu_token')){
      setHeader({
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      const response = await axios.get(TaskAPI+`/${id}` , {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      // console.log(id);
      // console.log(response.data.data);
      if (response.status === 200) {
        //const temp = JSON.parse(response.data.data);
        setTicket(response?.data?.data);
        setTags(response.data.data.tags)
        // console.log(ticket.tags);
        // console.log(ticket.tags);
        setSpinner(false)
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    }else{
      setHeader({
        headers: {
          menuboard: localStorage.getItem('store_token'),
        },
      })
      const response = await axios.get(TaskAPI+`/${id}` , {
        headers: {
          menuboard: localStorage.getItem('store_token'),
        },
      })
      // console.log(id);
    // console.log(response.data.data);
      if (response.status === 200) {
        //const temp = JSON.parse(response.data.data);
        setTicket(response.data.data);
        setTags(response.data.data.tags)
        // console.log(ticket.tags);
        // console.log(ticket.tags);
        setSpinner(false)
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    }
    //+ `?id=${id}`
    // console.log(TaskAPI+`/${id}`);
    
  } catch (error) {
    setSpinner(false)
    Toast(
      'err',
      error.response?.data?.msg || 'Something went wrong! Try again later.'
    )
  }
}
function showTags(){
  // console.log(tags);  
  return(
          <div style={{
            overflow:'auto'
          }}  
          >
          {tags.map((tag,id) => (
              <TicketTags text={tag} color={"#188FA7"}/>
          ))}
          </div>
    )
}
function editSubTaskFunction(data){
  console.log(data);
  setEditSubTaskModal(true);
  setSubTaskData(data);
}
  // console.log(ticket);
  return (
    <div >
      {/* <div className='col-3'>
        <DashBoard />
      </div> */}
      <div className='col-12'>
      <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => history.push('/ticketing-panel')}
          >
            Ticketing Panel
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Ticket</Breadcrumb.Item>
        </Breadcrumb>
      <div className='d-flex justify-content-between align-items-center'>
        </div>
        <h5 className='mt-4 fw-bold d-flex justify-content-start align-items-center'>
          Ticket Details
          {/* {spinner && <Spinner animation='border' size='sm' className='ms-2' />} */}
        </h5>
        <section className='ticket-details d-flex justify-content-between align-items-start'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='me-5 custom-header ticketDesc'>
              {/* 
                {tickets?.map((dt, idx) => (
                <tr key={idx} onClick={() => goToTicket(dt?.id)}>
                  <td> {dt.title} </td>
                  <td>{dt.tags}</td>
                  <td>{dt.currentStatus}</td>
                  <td>{dt.issuedBy}</td>
                  <td>{dt.issuedDate}</td>
                  <td>{dt.platform}</td>
                </tr>
              ))}
              */}
              {/* {console.log(ticket)} */}
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Title:</h6></div>
                  <div className='col-9'>{ticket?.title}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Tags:</h6></div>
                  <div className='col-9'>{showTags()}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Description:</h6></div>
                  <div className='col-9'>{ticket.description}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Store Name:</h6></div>
                  <div className='col-9'>{ticket.store_name}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Current Status:</h6></div>
                  <div className='col-9'>{!spinner && showProgress(ticket.status)}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Priority:</h6></div>
                  <div className='col-9'>{!spinner && showPriority(ticket.priority)}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Issued By:</h6></div>
                  <div className='col-9'>{ticket.issued_by}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Issued Date:</h6></div>
                  <div className='col-9'>{showDate(ticket.createdAt)}</div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Assigned to:</h6></div>
                  <div className='col-9'>
                    {ticket.assignee?(ticket.assignee):('Not Assigned')}
                  </div>
              </div>
              <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Platform:</h6></div>
                  {!spinner && (
                    <div className='col-9'>
                      <img   
                        className='me-3' 
                        src={ticket.platform === 'PC'? pcIcon : ticket.platform === 'Mobile'? mobileIcon : tvIcon} 
                        alt='' 
                      />
                      {ticket.platform}
                      
                    </div>
                  )}
                  
              </div>
              {/* <div className='row card-wide my-2'>
                  <div className='col-3'><h6>Sub-Task:</h6></div>
                  <div className='col-9'>
                    {
                      ticket.subTask.map((task, id)=>(
                        <p>{id+1}. {task}</p>
                      ))
                    }
                  </div>
              </div>               */}
            </div>
            {/* <div className='ms-5'>
              <h6>{storeData?.name}</h6>
              <h6>{storeData?.type}</h6>
              <h6>{storeData?.manager}</h6>
              <h6>{storeData?.phone}</h6>
              <h6>{storeData?.email}</h6>
              <h6>{storeData?.address}</h6>
              <h6>{storeData?.short_id}</h6>
              <h6>{storeData?.product_slider_interval}</h6>
              <h6>{storeData?.api_key}</h6>

              <h6>{storeData?.footer || '_'}</h6>
              <a
                href={storeData?.link || '_'}
                className='d-block'
                target='_blank'
              >
                <h6> Go to link</h6>
              </a>
              <a
                href={storeData?.social_link || '_'}
                className='d-block'
                target='_blank'
              >
                <h6>Go to social link</h6>
              </a>
              <h6>
                {storeData?.tag?.length > 0
                  ? storeData?.tag.map((dt, idx) => (
                      <span
                        key={idx}
                        style={{
                          color: 'black',
                          backgroundColor: '#e0e0e0',
                          padding: ' .3rem 1rem',
                          marginRight: '.7rem',
                          borderRadius: '4px',
                        }}
                      >
                        {dt}
                      </span>
                    ))
                  : 'N/A'}
              </h6>
            </div> */}
          </div>
          <button
            className='primary-btn-light'
            onClick={() => setEditTicketModal(true)}
          >
            Edit Ticket
          </button>
        </section>
        <section className='mt-4'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fw-bold'>
              Photos{' '}
             
            </h5>
            <button
              className='primary-btn d-flex justify-content-center align-items-center'
              onClick={handleClick}
            >
              {' '}
              <img className='me-3' src={plus} alt='' /> Add Photos {' '}
              {photoSpinner && <Spinner animation='border' size='sm' />}
            </button>
            <input 
              type="file" 
              multiple
              ref={hiddenFileInput} 
              onChange={handleImageUpload} 
              accept="image/*" 
              style={{display:'none'}}/>
          </div>
          <div className="image-preview">
            {/* {console.log(ticketImages)} */}
            {ticketImages.map((image, index) => (
              <div class="image-container">
                <img class="ticket-image" key={index} src={image.filepath} alt={`Uploaded image ${index + 1}`} />
                <button 
                  class="preview-button"
                  onClick={() => deleteTicketImage(image._id)}
                  >Delete</button>
              </div>
            ))}
          </div>
          {ticketImages.length===0 && (
                <div>
                  <h3 className='text-center text-muted my-3 py-3'>No Photos</h3>
                </div>
          )}

        </section>
        <section className='mt-4'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fw-bold'>
              <h2>Comments{' '}</h2>
              {/* {photoSpinner && <Spinner animation='border' size='sm' />} */}
            </h5>
          </div>
          <div className="comment-textbox">
            <textarea
              className="comment-input"
              placeholder="Write a comment..."
              value={comment}
              onChange={handleCommentChange}
            />
            <button className='primary-btn d-flex justify-content-center align-items-center mx-1 my-1' onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <div>
            {/* <Comments author="John" text="This is a great post!" id="0"/>
            <Comments author="Alice" text="I completely agree." id="0"/>
            <Comments author="Bob" text="Thanks for sharing!" id="0"/> */}
            {
              commentData.map((comment, index) => {
                return (
                    <Comments author={comment.author} text={comment.text} TaskID={id} commentID={comment._id} header={header} loadComment={loadComments} currentUser={userData}/>
                )
              })
            }
          </div>
          {commentData.length===0 && (
                <div>
                  <h3 className='text-center text-muted my-3 py-3'>No Comments</h3>
                </div>
          )}
        </section>
        {/* <section className='mt-4'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fw-bold'>
              Sub-Tasks{' '}
              {subTaskSpinner && <Spinner animation='border' size='sm' />}
            </h5>
            <button
              className='primary-btn d-flex justify-content-center align-items-center'
              onClick={() => setShowSubTaskModal(true)}
            >
              {' '}
              <img className='me-3' src={plus} alt='' /> Add Sub-Task
            </button>
          </div>
          {!subTaskSpinner &&(
            <>
            {subTasks.length != 0 && (
                <div>
                <Table
                  hover
                  striped
                  responsive
                  className='my-5 text-start'
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Description</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subTasks?.map((dt, idx) => (
                      <tr key={idx} onClick={() => editSubTaskFunction(dt)}>
                        <td className='align-middle'> {idx+1} </td>
                        <td className='align-middle'> {dt.description} </td>
                        <td> {!spinner && showProgress(dt.status)} </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </div>
            )}
            {subTasks.length===0 && (
                <div>
                  <h3 className='text-center text-muted my-5 py-5'>No Subtask</h3>
                </div>
            )}
             </>
          )}
        </section> */}
          
    </div>
    {/* <EditSubTicketModal
        show={editSubTaskModal}
        handleClose={() => setEditSubTaskModal()}
        data={subTaskData}
        ticketID={id}
        loadSubTicketData={loadSubTaskData}
    /> */}
    <EditTicketModal
        show={editTicketModal}
        handleClose={() => setEditTicketModal()}
        data={ticket}
        loadTicketData={loadTicketData} //TODO
        header={header}
        userData={userData}
    />
    {/* <AddSubTaskModal
        show={showSubTaskModal}
        handleClose={() => setShowSubTaskModal(false)}
        loadSubTaskData={loadSubTaskData}
        createSubTask={TaskAPI + `/${id}/subtasks`}
    /> */}
    </div>
  )
}

export default TicketPage