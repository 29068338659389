import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ActiveResetPassword = ({ show, handleClose, ResetPassword }) => {
    const [pass, setPass] = useState('');
    const [newPass, setNewPass] = useState('');

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <h4 className='fw-bold text-danger'>Caution!</h4>
                <h5>Please Update Your Password</h5>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none' }}>
                <button
                    className='primary-btn-light'
                    onClick={handleClose}
                >
                    Not now
                </button>
                <button
                    className="primary-btn d-flex justify-content-center align-items-center"
                    onClick={() => {
                        ResetPassword();
                        handleClose();
                    }}
                    type="submit"
                >
                    Update now
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActiveResetPassword;
