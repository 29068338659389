import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Spinner } from 'react-bootstrap';
import './Comments.scss'; // Import the CSS file for the component
import { FaUser } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap'
import { TaskAPI } from '../../constants/api.constants';
import Toast from '../../utils/Toast/Toast';
import threedot from '../../assets/icons/threedot.svg'

const Comments = ({ author, text, TaskID, commentID, loadComment, header, currentUser }) => {

  const [editComment, setEditComment] = useState(false);
  const [editSpinner, setEditSpinner] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(text);
  const [commentAction, setCommentAction] = useState(true);
  const [hasEditPermission, setHasEditPermission] = useState(true);
  const handlePermissions = () => {
    setHasEditPermission(true);
    if (localStorage.getItem('store_token')) {
      // const storeData = JSON.parse(localStorage.getItem('store_info'))
      if (currentUser.name != author) {
        setCommentAction(false);
      }
    } else if (localStorage.getItem('menu_token')) {
      if (currentUser.name != author) {
        setHasEditPermission(false);
      }
    }
  }
  useEffect(() => {
    handlePermissions();
  }, []);
  const handleEditClick = () => {
    setEditComment(true);
  };

  const handleInputChange = (event) => {
    setUpdatedComment(event.target.value);
  };

  const handleSaveClick = () => {
    if (updatedComment.trim() === '') {
      Toast('err', "Please write a comment")

    }
    else {
      handleEdit();

      setEditComment(false);
    }
  };
  const handleEdit = async () => {

    setEditSpinner(true);
    try {
      const response = await axios.put(TaskAPI + `/${TaskID}/comments/${commentID}`, {
        'author': author,
        'text': updatedComment,
      }, header)
      if (response.status === 200) {
        Toast('success', "Comment updated successfully")
        setEditSpinner(false);
        loadComment()
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg || 'Something went wrong')
      setEditSpinner(false);
    }
  }
  const handleDelete = async () => {
    try {
      const res = await axios.delete(TaskAPI + `/${TaskID}/comments/${commentID}`, header)
      if (res.status === 200) {
        Toast('success', 'Comment has been deleted successfully')
        loadComment()
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }

  return (
    <div className="comment">
      <div style={{ display: 'flex', gap: '1rem', width: '92.5%', marginLeft: '1.8%', marginBlock: '1%' }}>
        <div className="comment-user-icon">
          <FaUser />
        </div>
        <div className="comment-content">
          <div className="comment-author">
            {author ? (author) : ('Author')}{' '}
            {editSpinner && <Spinner animation='border' size='sm' className='ms-2' />}
          </div>
          {editComment ? (
            <textarea
              className="comment-edit-input"
              value={updatedComment}
              onChange={handleInputChange}
              placeholder='Write a comment'
            />
          ) : (
            <div className="comment-text">{text}</div>
          )}
        </div>
      </div>
      {
        editComment ? (
          <button className="comment-button" onClick={handleSaveClick}>
            Save
          </button>
        ) : (
          commentAction && (
            <div style={{ marginTop: '1.5%' }}>
              <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
                  <img src={threedot} alt='' className='' />
                </Dropdown.Toggle>

                <Dropdown.Menu className='mt-4'>
                  {
                    hasEditPermission && (
                      <Dropdown.Item className='fw-bold' onClick={() => handleEditClick()}>
                        Edit
                      </Dropdown.Item>
                    )
                  }

                  <Dropdown.Item className='fw-bold' onClick={() => handleDelete()}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )

        )
      }

    </div>
  );
};

export default Comments;