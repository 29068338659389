import React from 'react'
const TicketTags = ({ text,color }) => {
  return (
    <div
                      style={{
                        color: 'black',
                        backgroundColor: `${color}`,
                        float: 'left',
                        minWidth:'40px',
                        borderRadius: '15px',
                        border: '0.5px solid #BcBcBc',
                        boxShadow:'1px 3px 1px #ccc',
                        paddingTop: "3px",
                        paddingInline: '10px',
                        paddingBottom: "5px",                          
                        marginRight: '7px',
                        marginBlock:'5px',
                        textAlign: 'center',
                      }}
                    >  
                    {text} 
                </div>
  )
}

export default TicketTags