import React from 'react'
import { NavLink } from 'react-router-dom'
import './SideBar.scss'

const StoreSideBar = () => {
  return (
    <div>
      <div className=''>
        <ul className='list-group sticky-top  '>
          <NavLink as='li' className='list-group-item' to='/store' exact>
            <svg
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M29.1929 11.8533L27.7929 6.02667C27.4996 4.82667 26.4596 4 25.2463 4H6.72626C5.52626 4 4.47293 4.84 4.19293 6.02667L2.79293 11.8533C2.47293 13.2133 2.76626 14.6 3.6196 15.6933C3.72626 15.84 3.87293 15.9467 3.99293 16.08V25.3333C3.99293 26.8 5.19293 28 6.6596 28H25.3263C26.7929 28 27.9929 26.8 27.9929 25.3333V16.08C28.1129 15.96 28.2596 15.84 28.3663 15.7067C29.2196 14.6133 29.5263 13.2133 29.1929 11.8533V11.8533ZM25.2063 6.65333L26.6063 12.48C26.7396 13.04 26.6196 13.6 26.2729 14.04C26.0863 14.28 25.6863 14.6667 25.0196 14.6667C24.2063 14.6667 23.4996 14.0133 23.4063 13.1467L22.6329 6.66667L25.2063 6.65333V6.65333ZM17.3263 6.66667H19.9396L20.6596 12.6933C20.7263 13.2133 20.5663 13.7333 20.2196 14.12C19.9263 14.4667 19.4996 14.6667 18.9529 14.6667C18.0596 14.6667 17.3263 13.88 17.3263 12.92V6.66667ZM11.3129 12.6933L12.0463 6.66667H14.6596V12.92C14.6596 13.88 13.9263 14.6667 12.9396 14.6667C12.4863 14.6667 12.0729 14.4667 11.7529 14.12C11.4196 13.7333 11.2596 13.2133 11.3129 12.6933V12.6933ZM5.3796 12.48L6.72626 6.66667H9.35293L8.5796 13.1467C8.47293 14.0133 7.7796 14.6667 6.96626 14.6667C6.31293 14.6667 5.8996 14.28 5.72626 14.04C5.36626 13.6133 5.24626 13.04 5.3796 12.48ZM6.6596 25.3333V17.2933C6.76626 17.3067 6.8596 17.3333 6.96626 17.3333C8.12626 17.3333 9.1796 16.8533 9.95293 16.0667C10.7529 16.8667 11.8196 17.3333 13.0329 17.3333C14.1929 17.3333 15.2329 16.8533 16.0063 16.0933C16.7929 16.8533 17.8596 17.3333 19.0596 17.3333C20.1796 17.3333 21.2463 16.8667 22.0463 16.0667C22.8196 16.8533 23.8729 17.3333 25.0329 17.3333C25.1396 17.3333 25.2329 17.3067 25.3396 17.2933V25.3333H6.6596Z'
                fill='#333333'
              />
            </svg>

            <span className='ps-3'> Store</span>
          </NavLink>          
          <NavLink as='li' className='list-group-item' to='/ticketing-panel' exact>
          <svg
              viewBox="0 0 512 512"
              fill="#000"
              height="1.7em"
              width="1.7em"
            >
              <path d="M490.18 181.4l-44.13-44.13a20 20 0 00-27-1 30.81 30.81 0 01-41.68-1.6 30.81 30.81 0 01-1.6-41.67 20 20 0 00-1-27L330.6 21.82a19.91 19.91 0 00-28.13 0l-70.35 70.34a39.87 39.87 0 00-9.57 15.5 7.71 7.71 0 01-4.83 4.83 39.78 39.78 0 00-15.5 9.58l-180.4 180.4a19.91 19.91 0 000 28.13L66 374.73a20 20 0 0027 1 30.69 30.69 0 0143.28 43.28 20 20 0 001 27l44.13 44.13a19.91 19.91 0 0028.13 0l180.4-180.4a39.82 39.82 0 009.58-15.49 7.69 7.69 0 014.84-4.84 39.84 39.84 0 0015.49-9.57l70.34-70.35a19.91 19.91 0 00-.01-28.09zm-228.37-29.65a16 16 0 01-22.63 0l-11.51-11.51a16 16 0 0122.63-22.62l11.51 11.5a16 16 0 010 22.63zm44 44a16 16 0 01-22.62 0l-11-11a16 16 0 1122.63-22.63l11 11a16 16 0 01.01 22.66zm44 44a16 16 0 01-22.63 0l-11-11a16 16 0 0122.63-22.62l11 11a16 16 0 01.05 22.67zm44.43 44.54a16 16 0 01-22.63 0l-11.44-11.5a16 16 0 1122.68-22.57l11.45 11.49a16 16 0 01-.01 22.63z" />
            </svg>

            <span className='ps-3'> Ticketing Panel</span>
          </NavLink>
        </ul>
      </div>
    </div>
  )
}

export default StoreSideBar
