import axios from 'axios';
const HttpStatus = {
    OK: 200,
    // Add more status codes as needed
};

const handleResponse = (response) => {
    if (response.status === HttpStatus.OK) {
        return response;
    } else {
        throw new Error(response?.data?.msg);
    }
};

const handleError = (error) => {
    return Promise.reject(error);
};

// Create an Axios instance with the interceptors
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(handleResponse, handleError);

export default axiosInstance;