import React from 'react'
import { Redirect, Route } from 'react-router'
import { useAuth,authChecker } from '../../Providers/AuthProvider'
function PrivateRoute({ children, ...rest }) {
  const { user } = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?._id ? (
          <>
          {
            // authChecker()
            // user = ''
            
          }
          {children}
          </>
        ) : (
          <>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
            
          />
          </>
        )
      }
    />
  )
}
export default PrivateRoute
