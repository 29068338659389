import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { CreateAdmin, GetAllStoreAPI } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import AdminManagement from '../../../pages/AdminManagement/AdminManagement'
import { phoneRegex, emailRegex } from '../../../constants/regex'
import Frame from '../../../assets/images/istockphoto-1353841214-612x612.jpg'
import { NAME_REGEX } from '../../../constants/regex'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { lowerCaseRegex, minLengthRegex, upperCaseRegex, numberRegex, specialCharRegex, maxLengthRegex } from '../../../constants/regex'

import ReactPasswordChecklist from 'react-password-checklist'
import HideShowToggle from '../../HideShowToggle/HideShowToggle'
const NewAdminModal = ({ show, handleClose, loadAllAdmin, userData }) => {
  const [isloading, setisLoading] = useState(false)
  const [allStore, setAllStore] = useState([])
  const [passVisibility, setPassVisibility] = useState(false)
  const [passVisibility1, setPassVisibility1] = useState(false)
  const [store, setSelectedStoreId] = useState('');
  const [SelectedOptionsDropDown, setSelectedOptionsDropDown] = useState([])
  const phoneRegex = /^\+1\d{10}$/;

  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin',
    SUPER_ADMIN: "super_admin",
    OWNER: "owner"

  };
  const [passRetype, setPassReType] = useState('')

  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasMinLength: false,
    hasSpecialChar: false,
    hasMaxChar: false,
  });

  const validatePassword = (newPass, rePass) => {
    const hasLowerCase = lowerCaseRegex.test(newPass);
    const hasUpperCase = upperCaseRegex.test(newPass);
    const hasNumber = numberRegex.test(newPass);
    const hasMinLength = minLengthRegex.test(newPass);
    const hasSpecialChar = specialCharRegex.test(newPass); // Check for special character
    const hasMaxChar = maxLengthRegex.test(newPass); // Check for special character
    setValidations({ hasLowerCase, hasUpperCase, hasNumber, hasMinLength, hasSpecialChar, hasMaxChar });
  };
  const AssignRoles = {
    TICKETING_STAFF: 'Ticketing Staff',
    MANAGER: 'Manager',
    ADMIN: 'Admin'
  };


  const [data, setData] = useState({
    email: '',
    name: '',
    role: '',
    phone: '',
    password: '',
    // company_id: userData.company_id
  })

  const loadStoreData = async () => {
    let url = GetAllStoreAPI
    try {
      const response = await axios.get(url + '?pagesize=20', {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })


      if (response.status === 200) {
        const parsedData = JSON.parse(response.data.data);
        setAllStore(parsedData);

      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  const handleModalClose = () => {
    handleClose({
      email: '',
      name: '',
      role: '',
      phone: '',
      password: '',
    });
    setSelectedStoreId('');
    setSelectedOptionsDropDown([])
    setPassReType('')
    setData({
      email: '',
      name: '',
      role: '',
      phone: '',
      password: '',
    });
  };

  const handleSubmit = async () => {
    console.log("first", validations)

    try {
      setisLoading(true);
      let rolemessage = "";
      if (data.role === Roles.ADMIN) {
        rolemessage = AssignRoles.ADMIN;
      } else if (data.role === Roles.MANAGER) {
        rolemessage = AssignRoles.MANAGER;
      } else if (data.role === Roles.TICKETING_STAFF) {
        rolemessage = AssignRoles.TICKETING_STAFF;
      }
      if (data.name === '') {
        Toast('err', `Please enter your name `);
        setisLoading(false);
        return;
      }
      if (data.name.length < 3) {
        Toast('err', 'Name must be at least 3 characters long.');
        setisLoading(false);
        return;
      }
      if (data.email === '') {
        Toast('err', 'Please enter email');
        setisLoading(false);
        return;
      }

      if (!emailRegex.test(data.email)) {
        Toast('err', 'Please enter a valid email');
        setisLoading(false);
        return;
      }

      if (data.phone === '') {
        Toast('err', 'Please enter phone number');
        setisLoading(false);
        return;
      }

      if (!phoneRegex.test(data.phone)) {
        Toast('err', 'Invalid phone number');
        setisLoading(false);
        return;
      }

      if (data.role === '') {
        Toast('err', 'Please select role');
        setisLoading(false);
        return;
      }

      if ((data.role === Roles.MANAGER || data.role === Roles.TICKETING_STAFF) && store === '') {
        Toast('err', 'Please select store');
        setisLoading(false);
        return;
      }

      if (!validations?.hasLowerCase || !validations?.hasNumber || !validations?.hasUpperCase || !validations?.hasSpecialChar) { // Update conditions
        setisLoading(false)
        return
      }
      if (data.password !== passRetype) {
        setisLoading(false)
        return
      }
      if (data.password.length < 10 || data.password.length >= 32) {
        setisLoading(false)
        return;
    }


      let Store = null;
      if (data.role === Roles.MANAGER || data.role === Roles.TICKETING_STAFF) {
        Store = store;
      }

      const response = await axios.post(CreateAdmin, { ...data, store: Store, company_id: userData.company_id }, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      });

      if (response.status === 200) {
        Toast('success', `${rolemessage} Created!`);
        handleModalClose();
        setSelectedStoreId('');
        setisLoading(false);
        loadAllAdmin();
        setData({
          email: '',
          name: '',
          role: '',
          phone: '',
          password: '',
        });
        setPassReType('');
      } else {
        throw new Error(response?.data?.msg || 'Something went wrong! Try again later.');
      }
    } catch (error) {
      setisLoading(false);
      Toast('err', error.response?.data?.msg || 'Something went wrong! Try again later.');
    }
  };

  useEffect(() => {
    loadStoreData();
  }, [])

  const generateOptions = () => {
    if (userData?.effective_role == Roles.ADMIN || userData?.effective_role == Roles.SUPER_ADMIN) {
      const defaultOptions = allStore.map((store) => ({
        value: `${store._id}`,
        label: store.name
      }));
      return defaultOptions;
    }
    else if (userData?.effective_role === Roles.MANAGER) {
      const defaultOptions = allStore.filter(store => store._id === userData.store).map((store) => ({
        value: `${store._id}`,
        label: store.name
      }));
      return defaultOptions;
    }


    // if (
    //   userData?.effective_role === Roles.TICKETING_STAFF ||
    //   userData?.effective_role === Roles.MANAGER
    // ) {
    //   const userStore = allStore.find((data) => data._id === userData?.store);
    //   if (userStore) {
    //     return [{ value: `${userStore._id}`, label: userStore.name }, ...defaultOptions];
    //   }
    // }


  };

  // const generateOptionsfortickets = () => {


  //   if (
  //     userData?.effective_role === Roles.TICKETING_STAFF ||
  //     userData?.effective_role === Roles.MANAGER
  //   ) {
  //     const userStore = allStore.find((data) => data._id === userData?.store);
  //     if (userStore) {
  //       return [{ value: `${userStore._id}`, label: userStore.name }, ...defaultOptions];
  //     }
  //   }

  //   return defaultOptions;
  // };


  const handleStoreSelectionChange = (selectedStores, event) => {
    const selectedStoreIds = [];
    const selectedStoreNames = [];


    selectedStores.forEach((selectedStore) => {
      const [storeId] = selectedStore.value.split('|');
      selectedStoreIds.push(storeId);
    });

    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedOptionsDropDown([
        { label: "All Stores", value: "*" },
        ...allStore.map((store) => ({
          value: `${store._id}`,
          label: store.name
        }))
      ]);
      setSelectedStoreId(allStore.map(store => store._id),)

    } else if (event.action === "deselect-option" && event.option.value === "*") {
      setSelectedOptionsDropDown([]);
      setSelectedStoreId('')
    } else if (event.action === "deselect-option") {
      setSelectedOptionsDropDown(selectedStores.filter((o) => o.value !== "*"));
      setSelectedStoreId({
        store_id: selectedStores.filter((o) => o.value !== "*").map(store => store.value[0]),

      })

    } else if (selectedStores.length === allStore.length) {
      setSelectedOptionsDropDown([
        { label: "All Stores", value: "*" },
        ...allStore.map((store) => ({
          value: `${store._id}|${store.name}`,
          label: store.name
        }))
      ]);
      setSelectedStoreId(allStore.map(store => store._id))


    } else {
      setSelectedOptionsDropDown(selectedStores);
      setSelectedStoreId(selectedStoreIds)
    }
  };

  const handleCloseReset = () => {
    setData({
      email: '',
      name: '',
      role: '',
      phone: '',
      password: '',
    });
    setPassReType('');
  }

  return (
    <>
      <Modal show={show} onHide={() => { handleModalClose(); handleCloseReset() }}>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title>Add New Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='plain-input me-2'>
            <label for=''>Full Name</label>
            <br />
            <input
              type='text'
              placeholder='Enter your name'
              value={data?.name}
              onChange={(e) => {
                // Remove non-alphanumeric characters except '+'
                const sanitizedValue = e.target.value.replace(NAME_REGEX, '');
                setData({ ...data, name: sanitizedValue });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />

          </div>
          <div className='plain-input me-2 mt-2'>
            <label for=''>Email</label>
            <br />
            <input
              type='email'
              autoComplete='new-password' // Set autocomplete to "new-password"

              placeholder='Email@gmail.com'
              value={data?.email}
              onChange={(e) => {
                // Remove non-alphanumeric characters except '+'
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9\s@.]/g, '');
                setData({ ...data, email: sanitizedValue });
              }}
              // onChange={(e) => setData({ ...data, email: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}

            />
          </div>
          <div className='plain-input me-2 mt-2'>
            <label htmlFor=''>Phone</label>
            <br />

            <div className="input-group d-flex">
              <input
                type='text'
                value={data?.phone}
                placeholder='+1 (123) 456-7890'
                onChange={(e) => {
                  // Remove non-numeric characters except '+'
                  const sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
                  setData({ ...data, phone: sanitizedValue });
                }}
                autoComplete='new-password' // Set autocomplete to "new-password"

                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                pattern="[0-9]*"
              />
            </div>
          </div>



          <div className='plain-dropdown  mt-2'>
            <label for=''>Role</label>
            <select onChange={(e) => { setData({ ...data, role: e.target.value }); setSelectedOptionsDropDown(''); }}>
              <option value='' hidden>Select Role</option>
              <option value='ticketingstaff'>Ticketing-Staff</option>
              {
                userData?.effective_role === Roles.MANAGER || (
                  <>
                    <option value='manager'>Manager</option>
                    {
                      userData?.effective_role === Roles.ADMIN || (
                        <option value='admin'>Admin</option>
                      )
                    }


                  </>
                )
              }

              {/* <option value='super_admin'>super admin</option>{' '} */}
            </select>
          </div>
          { }
          {
            (data.role === Roles.MANAGER) && (
              <div className='plain-dropdown mt-2'>
                <label htmlFor=''>Store</label>
                <select onChange={(e) => setSelectedStoreId(allStore[e.target.value]._id)}

                >

                  <option value="" hidden>
                    Select Store
                  </option>
                  {allStore.length !== 0 ? (allStore.map((dt, idx) => (
                    <option key={idx} value={idx}>
                      {dt.name}
                    </option>
                  )
                  )) : (<option disabled >
                    No Store
                  </option>)}
                </select>
              </div>
            )
          }
          {
            data.role === Roles.TICKETING_STAFF && (
              <div className='plain-dropdown mt-2'>
                <label htmlFor=''>Store</label>
                <select
                  onChange={(e) => {
                    setSelectedStoreId(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Select Store
                  </option>

                  {userData.effective_role === Roles.MANAGER ? (
                    allStore.length !== 0 ? (allStore
                      .filter(store => store._id === userData.store)
                      .map((store, idx) => (
                        <option key={idx} value={store._id}>
                          {store.name}
                        </option>
                      ))) : ((<option disabled >
                        No Store
                      </option>))
                  ) : (
                    allStore.length !== 0 ? (
                      allStore.map((store, idx) => (
                        <option key={idx} value={store._id}>
                          {store.name}
                        </option>
                      ))) : (<option disabled >
                        No Store
                      </option>)
                  )}
                </select>
              </div>
            )
          }





          <div className='plain-input me-2 mt-2'>
            <label for=''>Password</label>
            <br />
            <input
              type={passVisibility ? 'text' : 'password'}
              placeholder='Minimum length 10'
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
                // validatePassword(e.target.value); // Change to newPass
              }}
              autoComplete='new-password' // Set autocomplete to "new-password"

              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
            <HideShowToggle
              passVisibility={passVisibility}
              setPassVisibility={() => setPassVisibility(!passVisibility)}
            />

          </div>
          <div className='plain-input me-2 mt-2'>
            <label htmlFor=''>Re-Type Password</label>
            <br />
            <input
              type={passVisibility1 ? 'text' : 'password'}
              placeholder='Re-type your password'

              onChange={(e) => {
                setPassReType(e.target.value);
                validatePassword(e.target.value); // Change to newPass
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
            <HideShowToggle
              passVisibility={passVisibility1}
              setPassVisibility={() => setPassVisibility1(!passVisibility1)}
            />

          </div>
          <ReactPasswordChecklist
            style={{ marginTop: "10px" }}
            rules={["minLength", "maxLength", "specialChar", "number", "capital", "lowercase", "match"]}
            minLength={10}
            maxLength={32}
            value={data?.password}
            valueAgain={passRetype}
            // valueAgain={passwordAgain}
            messages={{
              minLength: "Password length minimum 10 characters",
              maxLength: "Password length maximun 32 characters",
              specialChar: "Password has special character.",
              number: "Password has number.",
              capital: "Password has a capital letter",
              lowercase: "Password has a lowercase letter",
              match: "Password does not match"

            }}
          />


        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <button className='primary-btn-light ' onClick={() => { handleClose(); handleCloseReset() }}>
            Cancel
          </button>
          <button
            className='primary-btn d-flex justify-content-between align-items-center'
            onClick={() => handleSubmit()}
          >
            Add Admin{' '}
            {isloading && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}

          </button>
        </Modal.Footer>
      </Modal >

    </>
  )
}

export default NewAdminModal
