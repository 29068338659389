import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { FileUploadEnd, TaskCreate, TaskAPI, GetAdminApi, GetAllAdminApi } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import demoImg from '../../../assets/images/demoLogoImg.png'
import instock from '../../../assets/images/instock.jpg'
import outstock from '../../../assets/images/stock out-stock.jpg'
import "./AddTicketFrontal.scss"
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { TicketData } from '../../../constants/apifunction'
// import DatePicker from "react-datepicker";
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import { DatePicker } from 'rsuite';

export default function
  AddTicketFrontal({
    show,
    handleClose,
    loadTicketData,
    header,
    storeList,
    userData
  }) {
  const [editSpinner, setEditSpinner] = useState(false)
  // const [photoUrl, setPhotoUrl] = useState('')
  // const [dueDate, setDueDate] = useState(new Date());
  const [ticketImages, setTicketImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [allAdmins, setAllAdmins] = useState([])
  const [page, setPage] = useState(1)
  const [msgErr, setmsgErr] = useState('')
  const today = new Date().toISOString().slice(0, 10);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SelectedOptionsDropDown, setSelectedOptionsDropDown] = useState([])
  const [SelectedOptionsPlatform, setSelectedOptionsPlatform] = useState([])

  useEffect(() => {
    setSelectedOptionsDropDown();
  }, [storeList]);
  const [tickets, setTickets] = useState(
    {
      title: "",
      description: "",
      tags: [],
      status: "To-Do",
      platform: [],
      store_id: [],
      priority: "Low",
      due_date: "",
      store_name: [],
      assignee: "",
      subtask: []
    })
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const fileInputRef = useRef(null);
  const [assign, setSelectedAssign] = useState('');
  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin'
  };
  const PlatformEnum = {
    ALL: 'All PlatForm',
    // Add other platform options here if needed
  };
  const platformOptions = [
    { label: 'TV-app', value: 'TV-app' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'PC', value: 'PC' }
  ];
  const allowedExtensions = ["mp4", "webp", "mkv", "gif"];
  const Today = new Date()
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const token = localStorage.getItem('menu_token');
  const handleClear = () => {
    setTickets({
      title: "",
      description: "",
      tags: [],
      status: "To-Do",
      store_name: [],
      platform: [],
      store_id: [],
      priority: "Low",
      due_date: "",
      assignee: "",
      subtask: []
    })
    setmsgErr('')
    setSelectedOptionsDropDown([])
    setSelectedOptionsPlatform([])
  }
  useEffect(() => {
    if (token) {
      loadAllAdmin()
    }
  }, [userData])
  const loadAllAdmin = async () => {

    let url = `${GetAllAdminApi}`

    // if 
    if (userData?.effective_role) {
      url += '?role=ticketingstaff'
      setPage(1)
    }

    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setAllAdmins(response?.data?.data)
      } else
        throw new Error(
          response?.data?.msg || 'Cant load admins data, try again later'
        )
    } catch (error) {
      // Toast(
      //   'err',
      //   error.response?.data?.msg || 'Cant load admins data, try again later'
      // )
    }

    // useEffect(() => {

    // }, [])

  }
  function handleInput(e) {
    const { name, value } = e.target;
    if (name === 'due_date') {
      const today = new Date().toISOString().split('T')[0];

      if (value < today) { // Use "<" instead of "<=" to strictly check if due date is before today
        setmsgErr('Due date must be later than today.');
      } else {
        setmsgErr('');
      }
    }

    setTickets(prevTickets => ({
      ...prevTickets,
      [name]: value,
    }));
  }

  const [tags, setTags] = useState('')
  async function postTicketData() {
    const today = new Date().toISOString().split('T')[0];
    setEditSpinner(true)

    if (tickets.title === '') {
      Toast('err', 'Please enter title')
      setEditSpinner(false)
      return
    }
    if (tickets.description === '') {
      Toast('err', 'Please enter description')
      setEditSpinner(false)
      return
    }
    if (tickets.tags.length === 0) {
      Toast('err', "Please add atleast one tag!")
      setEditSpinner(false)
      return
    }
    if (!tickets.due_date) {
      Toast('err', 'Please select Date and valid Date');
      setEditSpinner(false);
      return;
    }
    if (tickets.store_name.length === 0 && localStorage.getItem('menu_token')) {
      Toast('err', 'Please select a store')
      setEditSpinner(false)
      return
    }
    if (tickets.platform.length === 0) {
      Toast('err', 'Please select a platform')
      setEditSpinner(false)
      return
    }

    if (tickets.due_date < today) {
      setEditSpinner(false)
      return;
    }
    if (tickets.assignee == "") {
      Toast('err', 'Please select a Assignee')
      setEditSpinner(false)
      return
    }

    // let temp = {
    //   ...(tickets.store_id ? {} : { store_id: userData?.store }),
    //   ...tickets
    // }

    // return
    try {
      const result = await TicketData(tickets, userData)

      if (result.status === 200) {
        const id = result.data.data._id;
        selectedImages.forEach(file => {
          const fileType = file.type.split('/')[1]; // Get the file extension
          if (fileType !== 'webp' && fileType !== 'gif') {
            const formData = new FormData();
            formData.append('file', file)
            uploadPhotoAPI(formData, id);
          }
        });
        setEditSpinner(false)
        Toast('success', 'Ticket created successfully');
        handleClose();
        loadTicketData();
        setTickets({
          title: "",
          description: "",
          tags: [],
          status: "To-Do",
          store_name: [],
          platform: [],
          store_id: [],
          priority: "Low",
          due_date: "",
          assignee: "",
          subtask: []
        })
        setSelectedImages([])
        setSelectedOptionsDropDown([])
        setSelectedOptionsPlatform([])
      } else throw new Error(result?.data?.msg)
    } catch (error) {
      setEditSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const enteredTag = event.target.value.trim();
      if (enteredTag) {
        if (tickets?.tags.includes(event.target.value) === false) {
          setTickets({
            ...tickets,
            tags: [...tickets.tags, event.target.value],
          })
          setTags('')
        }
      }
    }
  }
  function formatDate(dateString) {
    if (!dateString) return ''; // Handle empty or undefined date
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month; // Add leading zero if month is single digit
    }
    let day = date.getDate();
    if (day < 10) {
      day = '0' + day; // Add leading zero if day is single digit
    }
    return `${year}-${month}-${day}`;
  }

  const handleDeleteTag = (tags) => {
    let newArr = tickets?.tags.filter((t) => tags !== t)

    setTickets({ ...tickets, tags: newArr })
  }
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    if (files.length === 0) {
      // No files selected
      return;
    }

    const fileName = files[0].name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      Toast('err', 'Invalid image format');
      return

    }
    // if (fileExtension == "mp4" || fileExtension == "webp" || fileExtension == "mkv") {
    // }

    if (fileExtension) {
      if (files.length + selectedImages.length > 5) {
        Toast('err', 'You can not add more than 5 photos');
      } else {
        setSelectedImages(prevImages => [...prevImages, ...files]);
      }
    } else {
      setSelectedImages([]);
    }
  }


  const clearSelectedImages = () => {
    setSelectedImages([]);
    fileInputRef.current.value = null;
  };
  const uploadPhotoAPI = async (formData, id) => {
    try {
      const response = await axios.post(TaskAPI + `/${id}/pictures`, formData, header)
      if (response.status == 200) {
      }
    }
    catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }

  }

  const generateOptions = () => {
    if (storeList.length === 1) {
      return storeList.map((store) => ({
        value: `${store._id}|${store.name}`,
        label: store.name
      }));
    } else {
      return [
        { value: '*', label: 'All Stores' },
        ...storeList.map((store) => ({
          value: `${store._id}|${store.name}`,
          label: store.name
        }))
      ];
    }
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value?.some((o) => o?.value === "*")) {
      return `Select Store: All`;
    } else {
      return `Select Store: ${value?.length ?? 0} selected`;
    }
  }
  
  const handleStoreSelectionChange = (selectedStores, event) => {
    const selectedStoreIds = [];
    const selectedStoreNames = [];

    selectedStores.forEach((selectedStore) => {
      const [storeId, storeName] = selectedStore.value.split('|');
      selectedStoreIds.push(storeId);
      selectedStoreNames.push(storeName);
    });
    if (storeList.length === 1) {


      if (event.action === "select-option" && event.option.value === "*") {
        setSelectedOptionsDropDown([
          ...storeList.map((store) => ({
            value: `${store._id}|${store.name}`,
            label: store.name
          }))
        ]);
        setTickets({
          ...tickets,
          store_id: storeList.map(store => store._id),
          store_name: storeList.map(store => store.name)
        });
      } else if (event.action === "deselect-option" && event.option.value === "*") {
        setSelectedOptionsDropDown([]);
        setTickets({
          ...tickets,
          store_id: [],
          store_name: []
        });
      } else if (event.action === "deselect-option") {
        setSelectedOptionsDropDown(selectedStores.filter((o) => o.value !== "*"));
        setTickets({
          ...tickets,
          store_id: selectedStores.filter((o) => o.value !== "*").map(store => store.value.split('|')[0]),
          store_name: selectedStores.filter((o) => o.value !== "*").map(store => store.value.split('|')[1])
        });
      } else if (selectedStores.length === storeList.length) {
        setSelectedOptionsDropDown([
          ...storeList.map((store) => ({
            value: `${store._id}|${store.name}`,
            label: store.name
          }))
        ]);
        setTickets({
          ...tickets,
          store_id: storeList.map(store => store._id),
          store_name: storeList.map(store => store.name)
        });
      } else {
        setSelectedOptionsDropDown(selectedStores);
        setTickets({
          ...tickets,
          store_id: selectedStoreIds,
          store_name: selectedStoreNames
        });
      }
    }
    else{
      
      if (event.action === "select-option" && event.option.value === "*") {
        setSelectedOptionsDropDown([
          { label: "All Stores", value: "*" },
          ...storeList.map((store) => ({
            value: `${store._id}|${store.name}`,
            label: store.name
          }))
        ]);
        setTickets({
          ...tickets,
          store_id: storeList.map(store => store._id),
          store_name: storeList.map(store => store.name)
        });
      } else if (event.action === "deselect-option" && event.option.value === "*") {
        setSelectedOptionsDropDown([]);
        setTickets({
          ...tickets,
          store_id: [],
          store_name: []
        });
      } else if (event.action === "deselect-option") {
        setSelectedOptionsDropDown(selectedStores.filter((o) => o.value !== "*"));
        setTickets({
          ...tickets,
          store_id: selectedStores.filter((o) => o.value !== "*").map(store => store.value.split('|')[0]),
          store_name: selectedStores.filter((o) => o.value !== "*").map(store => store.value.split('|')[1])
        });
      } else if (selectedStores.length === storeList.length) {
        setSelectedOptionsDropDown([
          { label: "All Stores", value: "*" },
          ...storeList.map((store) => ({
            value: `${store._id}|${store.name}`,
            label: store.name
          }))
        ]);
        setTickets({
          ...tickets,
          store_id: storeList.map(store => store._id),
          store_name: storeList.map(store => store.name)
        });
      } else {
        setSelectedOptionsDropDown(selectedStores);
        setTickets({
          ...tickets,
          store_id: selectedStoreIds,
          store_name: selectedStoreNames
        });
      }
    }
  };

  const handlePlatformChange = (selectedOptions, event) => {
    if (event.option.label === PlatformEnum.ALL && event.action === 'select-option') {
      const updatedOptions = [{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions];
      setSelectedOptionsPlatform(updatedOptions);
      setTickets({
        ...tickets,
        platform: updatedOptions.slice(1).map(option => option.value)
      });
    } else if (event.option.label === PlatformEnum.ALL && event.action === 'deselect-option') {
      setSelectedOptionsPlatform([]);
      setTickets({
        ...tickets,
        platform: []
      });
    } else if (event.action === "deselect-option") {
      setSelectedOptionsPlatform(selectedOptions.filter((o) => o.value !== "*"));
      setTickets({
        ...tickets,
        platform: selectedOptions.filter((o) => o.value !== "*").map(option => option.value)
      });
    } else if (selectedOptions.length === platformOptions.length) {
      const updatedOptions = [{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions];
      setSelectedOptionsPlatform([
        { label: PlatformEnum.ALL, value: "*" },
        ...platformOptions
      ]);
      setTickets({
        ...tickets,
        platform: updatedOptions.slice(1).map(option => option.value)
      });
    } else {
      setSelectedOptionsPlatform(selectedOptions);
      setTickets({
        ...tickets,
        platform: selectedOptions.map(option => option.value)
      });
    }
  };



  return (
    <>
      <Modal show={show} onHide={() => { handleClose(); handleClear() }} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>
            Add New Ticket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='d-flex justify-content-start align-items-end'>
            <img
              src={photoUrl || demoImg}
              alt=''
              height='100'
              width='200'
              className='me-4'
            />

            <Form.Group className='' controlId='formBasicEmail'>
              <Form.Label>
                Image
                {photoSpinner && (
                  <Spinner className='ms-1' animation='border' size='sm' />
                )}
              </Form.Label>
              <Form.Control type='file' onChange={(e) => handleImageUpload(e)} />
            </Form.Group>
          </div> */}
          <div className='plain-input my-3'>
            <label for=''>Title</label>
            <br />
            <input
              type='text'
              placeholder='Enter title'
              value={tickets.title}
              onChange={handleInput}
              name='title'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postTicketData();
                }
              }}
            />
          </div>
          <div className='plain-input my-3'>
            <label htmlFor=''>Description</label>
            <br />
            <textarea
              className="plain-input"
              style={{
                width: '100%',
                height: '100px',
                padding: '1%',
                borderRadius: '5px',
                border: '1px solid #afafaf',
                outline: 'none', // Remove default focus outline
              }}
              placeholder='Enter description'
              value={tickets.description}
              onChange={handleInput}
              name='description'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postTicketData();
                }
              }}
              onFocus={(e) => {
                e.target.style.border = '1px solid rgb(4, 117, 4)';
              }}
              onBlur={(e) => {
                e.target.style.border = '1px solid #afafaf';
              }}
            />
          </div>

          <div className='plain-input my-3'>
            <label for=''>Tags</label>
            <br />
            <input
              type='text'
              placeholder='Enter your tags'
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <div className='d-flex justify-content-start align-items-center flex-wrap'>
            {tickets?.tags?.map((tags, idx) => (
              <span
                key={idx}
                className=' p-2  me-2 mb-2'
                style={{
                  color: 'black',
                  backgroundColor: '#e0e0e0',
                  borderRadius: '4px',
                }}
              >
                {tags}
                <TiDelete
                  style={{
                    marginLeft: '5px',
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteTag(tags)}
                />
              </span>
            ))}
          </div>
          <div className='plain-input mt-3'>
            <label htmlFor='endDate'>Due Date</label>
            <br />
            <input
              type="date"
              name='due_date'
              style={{ textTransform: 'uppercase' }}
              onChange={handleInput}
              value={tickets.due_date}
              min={new Date().toISOString().split('T')[0]} // Set min date to today
              max={new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split('T')[0]} // Set max date to 100 years from today
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postTicketData();
                }
              }}
            />

            {msgErr && <p style={{ color: 'red' }}>{msgErr}</p>}
          </div>

          {localStorage.getItem('menu_token') && (
            <div className='plain-dropdown mt-2'>
              <label htmlFor=''>Store Name</label>
              <div className='Multifield' style={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
                {storeList.length > 0 ? (
                  <ReactMultiSelectCheckboxes
                    value={SelectedOptionsDropDown}
                    className="multi-select"
                    getDropdownButtonLabel={getDropdownButtonLabel}
                    options={[...generateOptions()]}
                    onChange={handleStoreSelectionChange}
                  />
                ) : (
                  <select  >

                    <option value="" hidden>
                      Select Store
                    </option>
                    <option disabled >
                      No Store
                    </option>
                  </select>

                )}


              </div>
            </div>
          )}


          <div className='plain-dropdown  mt-2'>
            <label for=''>Priority</label>
            <select onChange={(e) => setTickets({ ...tickets, priority: e.target.value })}>
              {/*  urgent, high, low, medium*/}
              <option value='Low'>Low</option>
              <option value='Medium'>Medium</option>
              <option value='High'>High</option>
              <option value='Urgent'>Urgent</option>
            </select>
          </div>
          <div className='plain-dropdown mt-2'>
            <label htmlFor=''>Platform</label>
            <div className='Multifield' style={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>

              <ReactMultiSelectCheckboxes

                value={SelectedOptionsPlatform}
                className="multi-select"
                placeholderButtonLabel="Select Platform"
                options={[{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions]}
                onChange={handlePlatformChange}
              />
            </div>
          </div>
          <div className='plain-dropdown  mt-2'>
            <label for=''>Assignee</label>
            <select onChange={(e) => setTickets({ ...tickets, assignee: (e.target.value) })}>
              <option hidden >
                Select Assigner
              </option>

              {userData?.effective_role === Roles.MANAGER ? (
                allAdmins?.filter(data => data?.effective_role === Roles.TICKETING_STAFF)?.length > 0 ? (
                  allAdmins
                    .filter(data => data?.effective_role === Roles.TICKETING_STAFF)
                    .map((dt, idx) => (
                      <option key={idx} value={dt?.name}>
                        {dt?.name}
                      </option>
                    ))
                ) : (
                  <option disabled>
                    No Ticketing Staff
                  </option>
                )
              ) : (allAdmins?.length > 0 ?
                (allAdmins?.map((dt, idx) => (
                  <option key={idx} value={dt?.name}>
                    {dt?.name}
                  </option>
                ))) : (
                  <option disabled>
                    No Ticketing Staff
                  </option>
                )
              )}

            </select>
          </div>
          <div className='plain-input my-3'>
            <label>Photos</label><br />
            <div className="fileInputContainer">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="fileInput"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    postTicketData();
                  }
                }}
              />
              <label htmlFor="fileInput" className="fileInputLabel">
                <span>Choose Photos</span>
              </label>
            </div>
            {selectedImages.length > 0 && (
              <div className="imageContainer">
                <h2 className="subheading">Selected Image</h2>
                <div className="imagesWrapper">
                  {selectedImages.map((image, index) => (
                    <div key={index} className="imageItem">
                      <img src={URL.createObjectURL(image)} alt={`Selected ${index}`} className="image" />
                    </div>
                  ))}
                </div>
                <button onClick={clearSelectedImages} className="clearButton">Clear</button>
              </div>
            )}
          </div>


          {/* </form> */}
        </Modal.Body>
        <Modal.Footer>
          <button className='primary-btn-light' onClick={() => { handleClose(); handleClear() }}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postTicketData()}
            type='submit'
            disabled={editSpinner}
          >
            Add Ticket{' '}
            {editSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
