import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { AdEditEnd, FileUploadEnd } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import ReactPlayer from 'react-player'
import Frame from '../../../assets/images/Frame.png'
import ScrnImg from '../../../assets/images/screen-img.png'
import demoImg from '../../../assets/images/demoLogoImg.png'
const EditCard = ({ show, handleClose, ad, loadAllFolders, loadStoreData }) => {
  const [spinner, setSpinner] = useState(false)
  const MAX_FILE_SIZE_MB = 4; // Set the maximum file size limit in megabytes
  const [PhotoSpinner, setPhotoSpinner] = useState(false)
  const [data, setData] = useState({
    name: '',
    description: '',
    link: '',
    type: 'photo',
  })
  useEffect(() => {
    setData({
      name: ad?.name,
      description: ad?.description,
      link: ad?.link,
      type: ad?.type,
    })
  }, [ad])
  const Https = {
    OK: 200
  }



  const handleImageUpload = async (e) => {
    setPhotoSpinner(true)
    const file = e.target.files[0]

    const formData = new FormData()
    formData.append('files', file)

    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      if (res.status === Https.OK) {
        setData({ ...data, link: res.data?.files[0]?.path });
        Toast('success', 'File uploaded successfully')
        setPhotoSpinner(false)
      }
    } catch (error) {
      setData({ ...data, link: null });
      setPhotoSpinner(false)
    }
  }

  const handleEditAd = async () => {
    setSpinner(true)
    if (!data?.link) {
      Toast('err', 'Medial file must be provided')
      setSpinner(false)
      return
    }
    if (!data?.name) {
      Toast('err', 'Please enter ad name')
      setSpinner(false)
      return
    }
    if (!data?.description) {
      Toast('err', 'Please enter folder name')
      setSpinner(false)
      return
    }
    if (!data?.type) {
      Toast('err', 'Type must be provided')
      setSpinner(false)
      return
    }

    try {
      const response = await axios.put(
        AdEditEnd,
        { id: ad?._id, ...data },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === Https.OK) {
        Toast('success', 'AD Updated!')
        handleClose()
        setSpinner(false)
        loadStoreData()
        // loadAllInfo()
        setData({
          name: '',
          link: '',
          type: 'photo',
        })
      } else throw new Error(response.data?.msg || 'Try again later')
    } catch (error) {
      setSpinner(false)

      Toast('err', error.response?.data?.msg)
    }
  }
  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title style={{ fontSize: '22px' }}>Edit AD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-end' >
          {data?.type === 'photo' ? (
            <img
              src={data?.link?.includes('.mp4') ? demoImg : data?.link ?? demoImg}
              alt=''
              height='100'
              width='200'
              className='me-4'
            />
          ) : (
            <div className='mx-3'>
              <ReactPlayer
                url={data?.link}
                width='200px'
                height='100px'
                controls={true}
              />
            </div>
          )}

          <Form.Group className='' controlId='formBasicEmail'>
            <Form.Label>
              {data?.type}*
              {PhotoSpinner && (
                <Spinner className='ms-1' animation='border' size='sm' />
              )}
            </Form.Label>
            <Form.Control type='file' onChange={(e) => handleImageUpload(e)} />
          </Form.Group>
        </div>
        <div className='my-3'>
          <div className='plain-input my-3'>
            <label for=''>Ad Name* </label>
            <br />
            <input
              type='text'
              placeholder='Enter name'
              value={data?.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Ad Description* </label>
            <br />
            <input
              type='text'
              placeholder='Enter description'
              value={data?.description}
              onChange={(e) => setData({ ...data, description: e.target.value })}
            />
          </div>
          <div className='plain-dropdown my-3'>
            <label for=''>File Type*</label>
            <br />
            <select
              className=''
              onChange={(e) => setData({ ...data, type: e.target.value })}
              value={data?.type}
            >
              <option value='photo' >
                Photo
              </option>
              <option value='video'>Video</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className='primary-btn-light' onClick={handleClose}>
          Close
        </button>
        <button className='primary-btn' onClick={() => handleEditAd()}>
          Save Changes{' '}
          {spinner && <Spinner animation='border' size='sm' className='ms-2' />}
        </button>
      </Modal.Footer>
    </Modal>

  )
}

export default EditCard
